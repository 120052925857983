import jwtDecode from 'jwt-decode';
import { refreshKey, tokenKey } from './index';

export const validateToken = () => {
  const accessToken = localStorage.getItem(tokenKey);
  const refreshToken = localStorage.getItem(refreshKey);
  if (!accessToken && !refreshToken) {
    return false;
  }

  try {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (accessToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      if (currentTimestamp < decodedAccessToken.exp) {
        return true; // Access token is still valid
      }
    }

    // If we're here, either there's no access token or it's expired
    if (refreshToken) {
      const decodedRefreshToken = jwtDecode(refreshToken);
      if (currentTimestamp < decodedRefreshToken.exp) {
        try {
          return true;
        } catch (error) {
          console.error('Error refreshing token:', error);
          clearTokens();
          return false;
        }
      }
    }

    // If we're here, both tokens are either missing or expired
    clearTokens();
    return false;
  } catch (error) {
    console.error('Error validating token:', error);
    clearTokens();
    return false;
  }
};

const clearTokens = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshKey);
};

export const getTokenData = () => {
  const token = localStorage.getItem(tokenKey); // Replace with your actual access token
  if (!token) {
    return null;
  }
  const decodedToken = jwtDecode(token);
  return decodedToken;
};
