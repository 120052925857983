import * as d3 from 'd3';
import ColorParser from '../utils/colorParser';
import { hexToRgba } from '../utils/hexToRgba';
import { colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';

export const pieCircle = function pieCircle() {
  let config = {
    ...initialValues,
    piePadding: 20,
    overRideDefault: false,
    innerRadius: 0,
    arcLabelFontsize: 10,
    labelColor: '#000',
    subLabelColor: '#808080',
    enableCenterText: false,
    arcDividerStrokeColor: 'white',
    startAngle: 0,
    endAngle: Math.PI * 2,
    needleBreathing: 20,
    percentValue: true,
    isConcentric: false, // Add this configuration
    outerInnerRadius: 1.2, // Add this configuration to set outer donut size
    cornerRadius: 0,
  };

  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const t = d3
        .transition()
        .delay(function (d, i) {
          return i * 3;
        })
        .duration(config.duration);

      const mt = d3
        .transition()
        .delay(function (d, i) {
          return i * 3;
        })
        .duration(config.duration / 10);

      let clearArea =
        config.width < config.height
          ? config.width
          : config.enableNeedle && config?.dashboardType
          ? config.height + 35
          : config.enableNeedle
          ? config.height + 65
          : config.height;
      let clearArea2 =
        config.width < config.height ? config.width : config.height;

      if (!config.overRideDefault) {
        config = {
          ...config,
          piePadding: clearArea * 0.06,
        };
      }
      // Adjust the clearArea calculation to make the pie chart smaller
      if (config.width <= 872 && config.width >= 700) {
        clearArea =
          (clearArea / 1.8) * (config.width * 0.0012) - config.piePadding;
      } else if (config.width <= 700) {
        clearArea = clearArea = clearArea / 2 - config.piePadding;
      } else {
        clearArea = clearArea / 1.75 - config.piePadding;
      }
      clearArea2 = clearArea2 / 1.15 - config.piePadding;

      const scaleWidthHandler = () => {
        if (config?.width >= 255) {
          return config?.width / 1.75;
        } else if (config.width >= 230 && config?.width < 255) {
          return config?.width / 1.45;
        } else if (config.width >= 200 && config?.width < 230) {
          return config?.width / 1.35;
        } else if (config.width < 200 && config?.width >= 185) {
          return config?.width / 1.25;
        } else if (config.width < 185 && config?.width >= 175) {
          return config?.width / 1.15;
        } else if (config.width < 175 && config?.width >= 155) {
          return config?.width / 1.05;
        } else if (config.width < 155 && config?.width === 154) {
          return config?.width;
        } else if (config.width < 154 && config?.width >= 135) {
          return config?.width / 1.15;
        } else if (config?.width < 135 && config?.width >= 120) {
          return config?.width / 1.05;
        } else if (config?.width < 120 && config?.width >= 75) {
          return config?.width / 0.95;
        } else if (config?.width < 75) {
          return config?.width / 0.85;
        }
      };

      // move pie to right
      if (!config?.center) {
        selected.attr(
          'transform',
          'translate(' +
            ((config?.enableNeedle ? scaleWidthHandler() : config.width) -
              config.height +
              90) +
            ',' +
            130 +
            ')'
        );
      } else {
        clearArea = clearArea * 1.6;
      }

      const createPie = d3
        .pie()
        .startAngle(config.startAngle)
        .endAngle(config.endAngle)
        .value((d) => d.value)
        .sort(null);
      const flattenedData = data.flat();
      // Create a list of valid arcs
      const validArcs = flattenedData.filter((d) => d.value > 0);

      const arc = d3
        .arc()
        .cornerRadius(config.cornerRadius)
        .innerRadius((d, i) => {
          if (!config.enableNeedle) {
            let decrementAmount = 0;
            if (d.value > 0) {
              // Calculate decrement based on the position in validArcs list
              const validArcIndex = validArcs.findIndex(
                (arc) => arc.label === d.data.label
              );
              decrementAmount = (validArcIndex + 1) * 0.03;
            }

            // dynamically reduce inner arc width
            return config.graphType === 'pie'
              ? clearArea * config.innerRadius
              : clearArea -
                  (d.data.labelIndex + 1) *
                    (clearArea * (config.innerRadius - decrementAmount));
          } else {
            return config.graphType === 'pie'
              ? clearArea * config.innerRadius
              : clearArea -
                  (d.data.labelIndex + 1) * (clearArea * config.innerRadius);
          }
        })
        .outerRadius((d) =>
          config.graphType === 'pie'
            ? clearArea
            : clearArea - d.data.labelIndex * (clearArea * config.innerRadius)
        )
        .padAngle(config.padAngle ? config.padAngle : 0);
      const arc2 = d3
        .arc()
        .innerRadius((d, i) =>
          config.graphType === 'pie'
            ? clearArea2 * config.outerInnerRadius
            : clearArea2 -
              (d.data.labelIndex + 1) * (clearArea2 * config.outerInnerRadius)
        )
        .outerRadius((d) =>
          config.graphType === 'pie'
            ? clearArea2
            : clearArea2 -
              d.data.labelIndex * (clearArea2 * config.outerInnerRadius)
        )
        .padAngle(config.padAngle ? config.padAngle : 0);
      // const arc = d3
      //   .arc()
      //   .innerRadius((d, i) =>
      //     config.graphType === 'pie'
      //       ? clearArea2 * config.innerRadius
      //       : clearArea2 -
      //         (d.data.labelIndex + 1) * (clearArea2 * config.innerRadius)
      //   )
      //   .outerRadius((d) =>
      //     config.graphType === 'pie'
      //       ? clearArea2
      //       : clearArea2 - d.data.labelIndex * (clearArea2 * config.innerRadius)
      //   )
      //   .padAngle(config.padAngle ? config.padAngle : 0);

      function arcTween() {
        return function (d) {
          const interpolate = d3.interpolate(this._current, d);
          const _this = this;
          return function (t) {
            _this._current = interpolate(t);
            return arc(_this._current);
          };
        };
      }

      function arcTween1() {
        return function (d) {
          const interpolate = d3.interpolate(
            {
              ...this._current,
              startAngle: config.startAngle,
              endAngle: config.startAngle,
            },
            d
          );
          const _this = this;
          return function (t) {
            _this._current = interpolate(t);
            return arc(_this._current);
          };
        };
      }
      function arcTween2() {
        return function (d) {
          const interpolate = d3.interpolate(this._current, d);
          const _this = this;
          return function (t) {
            _this._current = interpolate(t);
            return arc2(_this._current);
          };
        };
      }

      function arcTween3() {
        return function (d) {
          const interpolate = d3.interpolate(
            {
              ...this._current,
              startAngle: config.startAngle,
              endAngle: config.startAngle,
            },
            d
          );
          const _this = this;
          return function (t) {
            _this._current = interpolate(t);
            return arc2(_this._current);
          };
        };
      }

      function centerGrp(elementRef) {
        elementRef.selectAll('*').remove();

        elementRef
          .append('text')
          .attr('class', 'circle-path-center-label')
          .style('text-anchor', 'middle')
          .style('font-weight', 'bold')
          .style('fill', config.labelColor)
          .style('font-size', '26px')
          .style('transform', (d, i) => {
            return `translate(0px, ${-1 * clearArea * 0.03}px)`;
          })
          .text((d) => d.value);

        elementRef
          .append('text')
          .attr('class', 'circle-path-center-sub-label')
          .style('text-anchor', 'middle')
          .style('font-size', '12px')
          .style('font-weight', 'bold')
          .style('fill', config.subLabelColor)
          .style('transform', (d, i) => {
            return `translate(0px, ${clearArea * 0.13 + 3}px)`;
          })
          .text((d) => d.label);
      }

      if (config.enableCenterText) {
        selected
          .selectAll('.circle-path-center-grp')
          .data([config.summary])
          .join(
            (enter) => {
              enter
                .append('g')
                .attr('class', 'circle-path-center-grp')
                .call(centerGrp);
            },
            (update) => update.call(centerGrp),
            (exit) => {
              exit.remove();
            }
          );
      } else {
        selected
          .selectAll('.circle-path-center-grp')
          .data([])
          .join(
            (enter) => {
              // No enter behavior needed since we're removing elements
            },
            (update) => {
              // No update behavior needed since we're removing elements
            },
            (exit) => {
              exit.remove();
            }
          );
      }

      selected
        .selectAll('.pie-path-grp')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'pie-path-grp');
          },
          (update) => update,
          (exit) => exit.remove()
        );

      selected
        .selectAll('.pie-path-grp')
        .selectAll('.pie-path')
        .data((d, i) => {
          return createPie(d);
        })
        .join(
          (enter) => {
            enter
              .append('path')
              .attr('class', 'pie-path')
              .attr('filter', (d, i) => {
                const color = d.data.color || ColorParser(colorBox[i]);
                const rgbaColor = hexToRgba(color, 0.6);
                return (
                  !config.enableNeedle &&
                  `drop-shadow(0px 20px 17px ${rgbaColor})`
                );
              })
              .transition(t)
              .attrTween('d', arcTween1())
              .style('stroke', config.arcDividerStrokeColor)
              .style('fill', (d, i) => {
                return d.data.color || ColorParser(colorBox[i]);
              })
              .style('stroke-width', '1px')
              .style('opacity', 1);
          },
          (update) =>
            update
              .attr('filter', (d, i) => {
                const color = d.data.color || ColorParser(colorBox[i]);
                const rgbaColor = hexToRgba(color, 0.6);
                return (
                  !config.enableNeedle &&
                  `drop-shadow(0px 20px 17px ${rgbaColor})`
                );
              })
              .transition(t)
              .style('stroke', config.arcDividerStrokeColor)
              .style('fill', (d, i) => {
                return d.data.color || ColorParser(colorBox[i]);
              })
              .style('stroke-width', '1px')
              .style('opacity', 1)
              .attrTween('d', arcTween()),
          (exit) => {
            exit.remove();
          }
        );

      // Adding concentric donut if isConcentric is true
      function generateReformattedData(originalData) {
        const reformattedData = [];

        originalData?.forEach((entry) => {
          if (
            Array.isArray(entry?.secondaryValue) &&
            entry.secondaryValue.length > 0
          ) {
            entry.secondaryValue.forEach((secondaryValue) => {
              const reformattedEntry = { ...entry, label: secondaryValue };
              reformattedData.push(reformattedEntry);
            });
          } else {
            const reformattedEntry = { ...entry, label: '' };
            reformattedData.push(reformattedEntry);
          }
        });

        return reformattedData;
      }

      if (config.isConcentric) {
        const formattedData = generateReformattedData(data[0]);
        selected
          .selectAll('.outer-pie-path-grp')
          .data([formattedData])
          .join(
            (enter) => {
              enter.append('g').attr('class', 'outer-pie-path-grp');
            },
            (update) => update,
            (exit) => exit.remove()
          );

        selected
          .selectAll('.outer-pie-path-grp')
          .selectAll('.outer-pie-path')
          .data((d, i) => {
            return createPie(d);
          })
          .join(
            (enter) => {
              enter
                .append('path')
                .attr('class', 'outer-pie-path')
                .transition(t)
                .attrTween('d', arcTween3())
                .style('stroke', config.arcDividerStrokeColor)
                .style('fill', (d, i) => {
                  return d.data.color || ColorParser(colorBox[i]);
                })
                .style('stroke-width', '1px')
                .style('opacity', 0.7); // Adjust opacity to distinguish layers
            },
            (update) =>
              update
                .transition(t)
                .style('stroke', config.arcDividerStrokeColor)
                .style('fill', (d, i) => {
                  return d.data.color || ColorParser(colorBox[i]);
                })
                .style('stroke-width', '1px')
                .style('opacity', 0.7) // Adjust opacity to distinguish layers
                .attrTween('d', arcTween2()),
            (exit) => {
              exit.remove();
            }
          );
        function labelDisplay(d) {
          const diff = d.endAngle - d.startAngle;

          return diff > 1;
        }

        // Calculate the maximum number of characters that fit inside the bubble
        function calculateMaxCharacters(radius, fontSize) {
          const availableWidth = 2 * radius - radius * 0.55; // Adjust padding as needed
          const avgCharacterWidth = fontSize * 0.6; // Adjust factor as needed
          return Math.floor(availableWidth / avgCharacterWidth);
        }

        config.arcLabel &&
          selected
            .selectAll('.outer-pie-path-grp')
            .selectAll('.pie-path-label')
            .data((d) => createPie(d))
            .join(
              (enter) => {
                enter
                  .append('text')
                  .attr('class', 'pie-path-label')
                  .style('text-anchor', 'middle')
                  .style('fill', 'white')
                  .style('pointer-events', 'none')
                  .attr('transform', (d) => {
                    const [x, y] = arc2.centroid(d).map((ele) => ele / 1);
                    const angle =
                      ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI) - 92;
                    return `translate(${x}, ${y}) rotate(${angle})`;
                  })
                  .transition(t)
                  .text((d) => {
                    const maxCharacters = 15; // Set to render 15 characters by default
                    if (maxCharacters) {
                      return (
                        d.data.label.substr(0, maxCharacters - 3) +
                        (maxCharacters - 3 < 1 ? '' : '')
                      );
                    }
                    return labelDisplay(d) ? d.data.label : '';
                  });
                // .text(function (d) {
                //   return labelDisplay(d) ? d.data.label : '';
                // });
                // .tween('text', (d, i, nodes) => {
                //   const interpolator = d3.interpolate(prevData[i], d)
                //   return (t) =>
                //     d3.select(nodes[i]).text(parseInt(interpolator(t).value))
                // })
              },
              (update) =>
                update
                  .transition(t)
                  .attr('transform', (d) => {
                    const [x, y] = arc2.centroid(d);
                    const angle =
                      ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI) - 92;
                    return `translate(${x}, ${y}) rotate(${angle})`;
                  })
                  .text((d) => {
                    const maxCharacters = 15; // Set to render 15 characters by default
                    if (maxCharacters) {
                      return (
                        d.data.label.substr(0, maxCharacters - 3) +
                        (maxCharacters - 3 < 1 ? '' : '')
                      );
                    }
                    return labelDisplay(d) ? d.data.label : '';
                  }),
              // .text(function (d) {
              //   return labelDisplay(d) ? d.data.label : '';
              // }),
              // .tween('text', (d, i, nodes) => {
              //   const interpolator = d3.interpolate(prevData[i], d)
              //   return (t) =>
              //     d3.select(nodes[i]).text(parseInt(interpolator(t).value))
              // })
              (exit) => {
                exit.remove();
              }
            );
      }
      function labelDisplay(d) {
        const diff = d.endAngle - d.startAngle;

        return diff > 1;
      }

      if (config.enableNeedle) {
        const needleSpec = {
          width: clearArea * (config.needleWidth || config.innerRadius / 4),
          height:
            clearArea -
            clearArea * (config.innerRadius + config.innerRadius * 0.2),
        };
        const valueToRadian =
          config.startAngle < 0
            ? (-1 * config.startAngle + config.endAngle) / 100
            : config.endAngle / 100;

        selected
          .selectAll('.band-bg')
          .select('defs')
          .data([0])
          .join(
            (enter) => {
              const defs = enter.append('defs');
              defs.selectAll('*').remove();

              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '0%')
                .attr('y2', '100%')
                .attr('id', 'gradOffset-indicator');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config.indicatorColor2 || '#000000')
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config.indicatorBottomColor || '#DDE1E6')
                .attr('stop-opacity', config.colorOpacityBottom || 1);

              // Gradient for the outer arc
              const outerArcGradient = defs
                .append('radialGradient')
                .attr('class', 'gradientOffset')
                .attr('cx', '50%')
                .attr('cy', '100%')
                .attr('r', '80%')
                .attr('id', 'outer-arc-gradient');

              outerArcGradient
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config.needleArcColor || '#ED3F47')
                .attr('stop-opacity', 0.2);

              outerArcGradient
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', '#fff')
                .attr('stop-opacity', 0.1);

              // create filterPositive with id #drop-shadow
              // height=200% so that the shadow is not clipped
              const filterCircle = defs
                .append('filter')
                .attr('id', 'drop-shadow-circle')
                .attr('height', '200%')
                .attr('width', '200%')
                .attr('x', '-50%')
                .attr('y', '-50%');

              // Add turbulence for dotted effect
              filterCircle
                .append('feTurbulence')
                .attr('type', 'fractalNoise')
                .attr('baseFrequency', 0.05)
                .attr('numOctaves', 1)
                .attr('result', 'turbulence');

              // Use feDisplacementMap to create the dotted spread effect
              filterCircle
                .append('feDisplacementMap')
                .attr('in', 'SourceGraphic')
                .attr('in2', 'turbulence')
                .attr('scale', 0)
                .attr('result', 'displacement');

              filterCircle
                .append('feGaussianBlur')
                .attr('in', 'displacement')
                .attr('stdDeviation', 1.5)
                .attr('result', 'blur');

              // translate output of Gaussian blur to the right and downwards with 2px
              // store result in offsetBlur
              filterCircle
                .append('feOffset')
                .attr('in', 'blur')
                .attr('dx', 0)
                .attr('dy', 2.5)
                .attr('result', 'offsetBlur');
              // overlay original SourceGraphic over translated blurred opacity by using
              // feMerge filterPositive. Order of specifying inputs is important!
              const feMerge = filterCircle.append('feMerge');

              feMerge.append('feMergeNode').attr('in', 'offsetBlur');
              feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

              // Draw the outer arc
              const outerArc = d3
                .arc()
                .innerRadius(clearArea * config.innerRadius * 0.2)
                .outerRadius(clearArea * (config.innerRadius + 0.7))
                .startAngle(config.startAngle);

              selected
                .selectAll('.outer-arc')
                .data([0])
                .join(
                  (enter) => {
                    enter
                      .append('path')
                      .attr('class', 'outer-arc')
                      .attr('d', outerArc.endAngle(config.endAngle))
                      .style('fill', 'url(#outer-arc-gradient)')
                      .transition(t)
                      .duration(config.duration / 2)
                      .ease(d3.easeLinear)
                      .attrTween('d', function (d) {
                        const interpolate = d3.interpolate(
                          config.startAngle,
                          config.startAngle +
                            valueToRadian * config.summary.rawValue
                        );
                        return function (t) {
                          return outerArc.endAngle(interpolate(t))();
                        };
                      });
                  },
                  (update) => update,
                  (exit) => {
                    exit.remove();
                  }
                );
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.circle-path-n')
          .data([config.summary])
          .join(
            (enter) => {
              const needle = enter
                .append('path')
                .attr('class', 'circle-path-n')
                .attr(
                  'd',
                  `M${needleSpec.width} 0 L${-1 * needleSpec.width} -0 L${
                    -1 * (needleSpec.width / 20)
                  } ${-1 * needleSpec.height} L${needleSpec.width / 5} ${
                    -1 * needleSpec.height
                  } Z`
                )
                .style(
                  'fill',
                  config.needleFillColor || 'url(#gradOffset-indicator)'
                )
                .style('stroke', config.needleStrokeColor || 'none')
                .style('stroke-width', config.needleStrokeWidth || 1)
                // .style('filter', 'url(#drop-shadow)')
                .style(
                  'transform',
                  `rotate(${config.startAngle * (180 / Math.PI)}deg)`
                );

              needle
                .transition(t)
                .duration(config.duration / 2)
                .ease(d3.easeLinear)
                .style('transform', (d) => {
                  const angle =
                    (config.startAngle + valueToRadian * d.rawValue) *
                    (180 / Math.PI);
                  return `rotate(${angle.toFixed(2)}deg)`;
                });

              enter
                .append('circle')
                .attr('class', 'needle-tip')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', config?.width < 160 ? 2.5 : 4)
                .attr('stroke', '#878D96')
                .attr('stroke-width', config?.width < 160 ? '0.1rem' : '0.2rem')
                .style('filter', 'url(#drop-shadow-circle')
                .style('fill', '#fff');
            },
            (update) => {
              update
                .attr(
                  'd',
                  `M${needleSpec.width} 0 L${-1 * needleSpec.width} -0 L${
                    -1 * (needleSpec.width / 20)
                  } ${-1 * needleSpec.height} L${needleSpec.width / 5} ${
                    -1 * needleSpec.height
                  } Z`
                )
                .style(
                  'fill',
                  config.needleFillColor || 'url(#gradOffset-indicator)'
                )
                .style('stroke', config.needleStrokeColor || 'none')
                .style('stroke-width', config.needleStrokeWidth || 1);
              // .style(
              //   'transform',
              //   `rotate(${config.startAngle * (180 / Math.PI)}deg)`
              // );

              update
                .transition(t)
                .duration(config.duration / 2)
                .ease(d3.easeLinear)
                .style('transform', (d) => {
                  const angle =
                    (config.startAngle + valueToRadian * d.rawValue) *
                    (180 / Math.PI);
                  return `rotate(${angle.toFixed(2)}deg)`;
                });

              update
                .append('circle')
                .attr('class', 'needle-tip')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', config?.width < 160 ? 2.5 : 4)
                .attr('stroke', '#878D96')
                .attr('stroke-width', config?.width < 160 ? '0.1rem' : '0.2rem')
                .style('filter', 'url(#drop-shadow-circle')
                .style('fill', '#fff');
            },
            (exit) => {
              exit.remove();
            }
          );
      }

      // Calculate the maximum number of characters that fit inside the bubble
      function calculateMaxCharacters(radius, fontSize) {
        const availableWidth = 2 * radius - radius * 0.55; // Adjust padding as needed
        const avgCharacterWidth = fontSize * 0.6; // Adjust factor as needed
        return Math.floor(availableWidth / avgCharacterWidth);
      }

      // Define the label arc for label positioning
      const labelArc = d3
        .arc()
        .innerRadius((d) => arc.outerRadius()(d))
        .outerRadius((d) => arc.outerRadius()(d));

      config.arcLabel &&
        selected
          .selectAll('.pie-path-grp')
          .selectAll('.pie-path-label')
          .data((d) => createPie(d))
          .join(
            (enter) => {
              enter
                .append('text')
                .attr('class', 'pie-path-label')
                .style(
                  'text-anchor',
                  config?.showInitialLabels ? 'end' : 'middle'
                )
                .style('fill', config?.showInitialLabels ? 'black' : 'white')
                .style('pointer-events', 'none')
                .style('font-size', config?.width < 155 ? 9 : 11)
                .style('font-weight', config?.showInitialLabels && '500')
                // .attr('dx', config.showInitialLabels && -12)
                // .attr('startOffset', config.showInitialLabels && '0%')
                .attr('transform', (d) => {
                  const [x, y] = labelArc.centroid(d);
                  let angle =
                    ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI) - 90;
                  // Check if the text is on the left side and invert if necessary

                  if (config?.showInitialLabels) {
                    if (d?.index === 0) {
                      angle = 0;
                      return `translate(${x - 8}) rotate(${angle})`;
                    }
                    if (d?.index === 1) {
                      angle = 0;
                      return `translate(${-59.0735}, ${-7.894398}) rotate(${angle})`;
                    }
                    if (d?.index === 2) {
                      angle = 0;
                      return `translate(${x - 20}, ${y + 20}) rotate(${angle})`;
                    }
                    if (d?.index === 3) {
                      angle = 0;
                      return `translate(${x - 20}, ${y - 3}) rotate(${angle})`;
                    }
                    if (d?.index === 4) {
                      angle = 0;
                      return `translate(${x - 10}, ${
                        y - (config?.width < 155 ? 35 : 45)
                      }) rotate(${angle})`;
                    }
                  }
                  if (x < 0) {
                    angle += 180;
                  }

                  return `translate(${x}, ${y}) rotate(${angle})`;
                })
                .transition(t)
                .text((d) => {
                  const label = d.data.label;
                  const maxCharacters = 14;
                  if (Math.abs(d.startAngle - d.endAngle) < 0.2) {
                    return ''; // Don't show the label
                  }
                  // If label length is greater than maxCharacters, truncate and append '...'
                  if (label.length > maxCharacters) {
                    return `${label.substr(0, maxCharacters - 3)}...`;
                  }
                  // Else, show the full label
                  return label;
                });
            },
            (update) =>
              update
                .style('font-size', config?.width < 155 ? 9 : 11)
                .transition(t)
                .attr('transform', (d) => {
                  const [x, y] = labelArc.centroid(d);
                  let angle =
                    ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI) - 90;

                  // Check if the text is on the left side and invert if necessary
                  if (config?.showInitialLabels) {
                    if (d?.index === 0) {
                      angle = 0;
                      return `translate(${x - 8}) rotate(${angle})`;
                    }
                    if (d?.index === 1) {
                      angle = 0;
                      return `translate(${-59.0735}, ${-7.894398}) rotate(${angle})`;
                    }
                    if (d?.index === 2) {
                      angle = 0;
                      return `translate(${x - 20}, ${y + 20}) rotate(${angle})`;
                    }
                    if (d?.index === 3) {
                      angle = 0;
                      return `translate(${x - 30}, ${y - 3}) rotate(${angle})`;
                    }
                    if (d?.index === 4) {
                      angle = 0;
                      return `translate(${x - 10}, ${
                        y - (config?.width < 155 ? 35 : 45)
                      }) rotate(${angle})`;
                    }
                  }
                  if (x < 0) {
                    angle += 180;
                  }

                  return `translate(${x}, ${y}) rotate(${angle})`;
                })
                .text((d) => {
                  const label = d.data.label;
                  const maxCharacters = 14;
                  if (Math.abs(d.startAngle - d.endAngle) < 0.2) {
                    return ''; // Don't show the label
                  }
                  // If label length is greater than maxCharacters, truncate and append '...'
                  if (label.length > maxCharacters) {
                    return `${label.substr(0, maxCharacters - 3)}...`;
                  }

                  // Else, show the full label
                  return label;
                }),
            (exit) => {
              exit.remove();
            }
          );

      setUpEvents(config, selected, 'pie-path');
      setUpEvents(config, selected, 'outer-pie-path'); // events for secondary pie
    });

    return selected;
  }

  graph.config = function (value) {
    if (!arguments.length) return config;
    config = {
      ...config,
      ...value,
    };
    return graph;
  };

  return graph;
};
