import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import graphBucket from '../utils/graphBucket';
import { initialValues, colorBox, sovColorBox } from '../utils/graphConst';
import {
  purpleColorGradients,
  magentaColorGradients,
} from '../../constants/graph-colors';
import { getGraphUtils } from '../utils/graphUtils';
import { getAccArr } from '../CoolColumn/columnUtils';

class LinearLineGraph extends RootGraph {
  setData(data) {
    const inData = JSON.parse(JSON.stringify(data));
    if (inData.title === 'Results over time' && !this?.config?.fromNewsletter) {
      const onlineData = [];
      const printData = [];
      let totalOnlineValue = 0;
      let totalPrintValue = 0;

      // First loop to calculate the totals
      for (let i = 0; i < inData.data.length; i++) {
        totalOnlineValue += inData.data[i].onlineCount;
        totalPrintValue += inData.data[i].printCount;
      }

      // Loop to populate onlineData and printData arrays
      for (let i = 0; i < inData.data.length; i++) {
        const dataPoint = inData.data[i];
        const commonItem = {
          label: dataPoint.label,
          colorOpacity: inData.labels[0].colorOpacity,
          color1: inData.labels[0].color1,
          colorOpacity1: inData.labels[0].colorOpacity1,
          labelText: inData.labels[0].label,
          index: i,
          labelIndex: 0,
        };

        const onlineItem = {
          ...commonItem,
          value: dataPoint.onlineCount,
          color: purpleColorGradients.purple60,
          dashLineColor: purpleColorGradients.purple60,
          accValue:
            (i > 0 ? onlineData[i - 1]?.accValue : 0) + dataPoint.onlineCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalOnlineValue, // Add total online value to each rawData object
            mediaType: inData.summary.mediaType,
          },
        };

        const printItem = {
          ...commonItem,
          value: dataPoint.printCount,
          color: magentaColorGradients.magenta50,
          dashLineColor: magentaColorGradients.magenta50,
          accValue:
            (i > 0 ? printData[i - 1]?.accValue : 0) + dataPoint.printCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalPrintValue, // Update and add total print value
            mediaType: inData.summary.mediaType,
          },
        };

        if (inData.summary.mediaType === null) {
          onlineData.push(onlineItem);
          printData.push(printItem);
        } else {
          if (inData.summary.mediaType !== 'Print') {
            onlineData.push(onlineItem);
          }
          if (inData.summary.mediaType !== 'Online') {
            printData.push(printItem);
          }
        }
      }

      // push only required data based on media type
      this.graphData = [];
      if (inData.summary.mediaType === null) {
        if (onlineData.length > 0) this.graphData.push(onlineData);
        if (printData.length > 0) this.graphData.push(printData);
      } else {
        if (inData.summary.mediaType !== 'Print' && onlineData.length > 0) {
          this.graphData.push(onlineData);
        }
        if (inData.summary.mediaType !== 'Online' && printData.length > 0) {
          this.graphData.push(printData);
        }
      }
    } else {
      this.labels = inData?.labels
        ? inData?.labels
        : [{ label: 'label', value: 'value' }];
      this.data = inData.data;
      this.summary = inData.summary;

      this.filteredData = this.data;
      const formattedData = [];
      for (let k = 0; k < this.labels.length; k++) {
        const items = [];
        for (let i = 0; i < this.filteredData?.length; i++) {
          const value = this.filteredData[i][this.labels[k].value];
          const item = {
            ...this.labels[k],
            data: this.filteredData[i],
            label: this.filteredData[i].label,
            value: parseFloat(value),
            accValue:
              (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
                ? formattedData[k - 1][i]?.accValue
                : 0) + parseFloat(value),
            labelText: this.labels[k].label,
            index: i,
            labelIndex: k,
            color: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : sovColorBox[k] ||
                colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            rawData: this.filteredData[i],
          };
          items.push(item);
        }
        formattedData.push(items);
      }
      this.graphData = formattedData;
    }
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    super.drawGraph();
    const data = this.graphData[0];
    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'line', // line, sarea, area, rband
      summary: this.summary,
      ...this.config,
      gridXTicks: 8,
      padding: { ...initialValues.padding, ...this.config.padding },
    };

    const { minX, maxX, minY, maxY, graphAreaH, graphAreaW, graphAreaL } =
      getGraphUtils(
        config,
        config.graphType === 'sarea'
          ? getAccArr(this.graphData)
          : this.graphData.flat(Infinity)
      );

    const xScale = d3
      .scalePoint()
      .range([0, graphAreaW * (config.graphAreaWMultiplayer || 0.95)])
      .domain(
        data.map(function (d, i) {
          return d.label;
        })
      );

    const xScaleN = d3
      .scaleLinear()
      .range([0, graphAreaW * (config.graphAreaWMultiplayer || 0.95)])
      .domain([minX < 0 ? minX : 0, maxX + (maxX / 100) * 10]);

    const yScale = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([
        minY < 0 ? minY : 0,
        config.yDomain || maxY + maxY * (config.yDomainMultiplayer || 0.25),
      ]);

    config = {
      ...config,
      xScaleN,
      xScale,
      yScale,
      graphAreaH,
      graphAreaW,
      minY,
      maxY,
      showAllLabels: true,
    };

    // x-axis
    const xAxis = graphBucket.xAxis().config(config);
    this.$graphGrp.datum([data]).call(xAxis);

    // y-axis
    const yAxis = graphBucket.yAxis().config(config);
    this.$graphGrp.datum([data]).call(yAxis);

    //  Lines
    const lines = graphBucket.linePath().config(config);
    const lineGrps = this.$graphGrp.selectAll('.linePathGroup').node()
      ? this.$graphGrp.selectAll('.linePathGroup')
      : this.$graphGrp.append('g');

    lineGrps
      .attr('class', 'linePathGroup')
      .attr('transform', 'translate(' + graphAreaL + ',0)')
      .datum(this.graphData)
      .call(lines);
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.line-bg-rect');
    const classSelected2 = this.$graphGrp.selectAll('.peak-circle-grp');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
    classSelected2.classed('selected', false);
    classSelected2.classed('unselected', false);
  }
}
export default LinearLineGraph;
