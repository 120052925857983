import styled from 'styled-components';

// Chart Labels text
export const ChartLabelExtraLargeWrp = styled.p`
  //styleName: Label/XLarge;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
  padding: 0;
  margin: 0;
  padding: 0;
  margin: 0;
`;

export const ChartLabelLargeWrp = styled.p`
  //styleName: Label/Large;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
  padding: 0;
  margin: 0;
`;

export const ChartLabelSmallWrp = styled.div`
  //styleName: Label/Small;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
  padding: ${({ padding }) => padding ?? 0};
  margin: 0;
  min-width: ${({ minWidth }) => minWidth ?? 0};
`;

export const ChartLabelExtraSmallWrp = styled.p`
  //styleName: Label/XSmall;
  font-family: Inter;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
  margin: 0;
  padding: 0;
`;

// Sub titles text

export const SubTitleWrp = styled.div`
  //styleName: Subtitle/Medium;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
`;

export const SubTitleSmallWrp = styled.div`
  //styleName: Subtitle/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
`;

// Body text

export const BodyTextSmallWrp = styled.div`
  //styleName: Body/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
`;

export const BodyTextExtraSmallWrp = styled.div`
  //styleName: Body/XSmall;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
`;

export const BodyTextDoubleSmallWrp = styled.div`
  //styleName: Body/XXSmall;
  font-family: Inter;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray100};
`;
