import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
// import { getUserDetails } from './redux/slices/userSlice';
// import NavSection from './components/nav-section';
import AMXRoutes from './routes';
import { theme } from './constants/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/error-handler';
import MobileView from './components/mobile-view';
import { Toaster } from 'react-hot-toast';
import { getTokenData, validateToken } from './constants/validateToken';
import * as Sentry from '@sentry/react';
import { AwsRum } from 'aws-rum-web';
import {
  awsApplicationId,
  awsApplicationVersion,
  awsEndPoint,
  awsIdentityPoolId,
  awsRegion,
} from './constants';
import { handleChunkError, logAwsRumEvent } from './utils';
import { events } from './constants/widgets';
import { userRole } from './redux/slices/userSlice';
// import { Sendrequest, requestForToken } from './utils/firebase';
export let awsRum;

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: awsIdentityPoolId,
    endpoint: awsEndPoint,
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    recordAllRequests: true,
    enableXRay: false,
  };

  const APPLICATION_ID = awsApplicationId;
  const APPLICATION_VERSION = awsApplicationVersion;
  const APPLICATION_REGION = awsRegion;

  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Create a client
const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function App({ titles }) {
  const isValid = validateToken();
  const dispatch = useDispatch();
  const GlobalStyle = createGlobalStyle`
  button{
    font-family:${({ theme }) => theme.fontFamily}
  }
   .mobile-view {
      display: none;
    }
    .desktop-view {
      display: block;
    }
 
  @media only screen and (max-width: 0px) {
   
     .mobile-view {
    display: block;
  }

  .desktop-view {
    display: none;
  }
  }
  `;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_ENVIRONMENT || 'development-FE', // Set the environment
  });

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  useEffect(() => {
    document.title = titles;
  }, [titles]);

  useEffect(() => {
    const isValid = validateToken();
    if (isValid) {
      const tokenData = getTokenData();
      Sentry.setUser({ id: tokenData?.user_id });
    } else {
      Sentry.setUser(null);
    }
  }, []);

  useEffect(() => {
    const token = getTokenData();
    if (token) {
      if (token.role) {
        dispatch(userRole(token.role));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('error', handleChunkError);

    return () => {
      window.removeEventListener('error', handleChunkError);
    };
  }, []);

  // useEffect(() => {
  //   requestForToken(setToken);
  // }, []);

  // useEffect(() => {
  //   Sendrequest();
  // }, []);

  // requestForToken();

  // onMessageListener().then((payload) => {
  //   console.log(payload);
  //   setToken((old) => !old);
  // });

  const SentryErrorBoundary = Sentry.withErrorBoundary(ErrorBoundary);

  return (
    <>
      <SentryErrorBoundary
        FallbackComponent={ErrorHandler}
        onError={(error) => {
          console.log({ error });
          Sentry.captureException(error);
          logAwsRumEvent(events.function_error_unhandled_log, {
            error,
          });
          logAwsRumEvent(events.function_error_unhandled_log, {
            error: error?.stack,
          });
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={{ theme, ...theme[selectedTheme] }}>
            <GlobalStyle />

            {/* <NavSection /> */}
            <div className="desktop-view" id="desktop">
              <AMXRoutes isAuthenticated={isValid} />
            </div>
            <div className="mobile-view">
              <MobileView />
            </div>
            <Toaster
              toastOptions={{
                className: '',
                style: {
                  fontSize: '0.85rem',
                },
              }}
            />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </SentryErrorBoundary>
    </>
  );
}

export default App;
