import * as d3 from 'd3';
import { colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import { rectLabels, topLabels } from './labelUtils';
import ColorParser from '../utils/colorParser';
import { hexToRgba } from '../utils/hexToRgba';

export const columnRect = function columnRect() {
  let config = {
    ...initialValues,
    enableTopLabels: false,
    enableRectLabels: false,
    columnWidth: 16,
    columnPadding: 2,
    columnGroupPadding: 2,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);

  function addGradient(selected, data, breakDownData) {
    const defs = selected.append('defs');
    if (config?.enableTextForSourceAndJournalist) {
      data.forEach((d, i) => {
        const gradientId = `gradient-${d?.label?.replace(
          /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
          ''
        )}-${i}`;
        const gradient = defs
          .append('linearGradient')
          .attr('id', (d, id) => gradientId)
          .attr('x1', '0%')
          .attr('y1', '100%')
          .attr('x2', '0%')
          .attr('y2', '0%');

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', d.lightColor) // Start color from data
          .attr('stop-opacity', 1);

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', d.color) // End color from data
          .attr('stop-opacity', 1);
      });
    } else if (config.breakDownByMedia || config.mediaTypeChart) {
      breakDownData.flat().forEach((d, i) => {
        const gradientId = `gradient-${d?.label?.replace(
          /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
          ''
        )}-${d.labelText?.replace(
          /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
          ''
        )}`;

        const gradient = defs
          .append('linearGradient')
          .attr('id', (d, id) => gradientId)
          .attr('x1', '0%')
          .attr('y1', '100%')
          .attr('x2', '0%')
          .attr('y2', '0%');

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', d.lightColor) // Start color from data
          .attr('stop-opacity', 1);

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', d.color) // End color from data
          .attr('stop-opacity', 1);
      });
    }
    if (config.haveGradient) {
      breakDownData.flat().forEach((d, i) => {
        const gradientId = `gradient-${d?.label?.replace(
          /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
          ''
        )}-${d.labelText?.replace(
          /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
          ''
        )}`;

        const gradient = defs
          .append('linearGradient')
          .attr('id', (d, id) => gradientId)
          .attr('x1', '0%')
          .attr('y1', '100%')
          .attr('x2', '0%')
          .attr('y2', '0%');

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', hexToRgba(d.color, 0.4)) // Start color from data
          .attr('stop-opacity', 1);

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', d.color) // End color from data
          .attr('stop-opacity', 1);
      });
    }
  }

  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const xWidth = config.xScale.bandwidth() - config.columnPadding;
      if (
        config.graphAreaW < 1200 &&
        config?.graphAreaW >= 800 &&
        (data[0]?.length === 6 || data[0]?.length === 5) &&
        config.enableTextForSourceAndJournalist
      ) {
        config.columnWidth = 16;
      } else if (
        config.graphAreaW < 800 &&
        (data[0]?.length === 6 || data[0]?.length === 5) &&
        config.enableTextForSourceAndJournalist
      ) {
        config.columnWidth = 12;
        config.fontSize = 9;
      }
      config.columnWidth =
        config.columnWidth < xWidth ? config.columnWidth : xWidth;

      const noDataLabels = data.reduce((acc, arr) => {
        arr.forEach((entry) => {
          if (!acc[entry.label]) {
            acc[entry.label] = [];
          }
          acc[entry.label].push(entry.value);
        });
        return acc;
      }, {});

      // Check if all values are zero for each label
      const labelsToShowNoData = Object.entries(noDataLabels)
        .filter(([label, values]) => values.every((value) => value === 0))
        .map(([label]) => label);
      // Filter to ensure only unique labels are used for "No Data" text
      const uniqueLabels = [...new Set(labelsToShowNoData)];
      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'bar-group');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      addGradient(selected, data[0], data);

      if (config.topLabels) {
        const totalLabelFunc = function (eleRef) {
          const formatNumber = d3.format('.0f'); // Format to 2 decimal places
          eleRef
            .attr('x', (d) => {
              return config.xScale(d.label) + config.xScale.bandwidth() / 2;
            })
            .attr('y', (d) => {
              return config.yScale(d.accValue) - 10;
            })
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'baseline')
            .attr('font-size', '13px')
            .attr('fill', '#697077')
            .attr('font-weight', '600')
            .text((d) => {
              return formatNumber(d.accValue) + '%';
            });
        };

        selected
          .selectAll('.total-label')
          .data(data[data.length - 1])
          .join(
            (enter) => {
              enter
                .append('text')
                .attr('class', 'total-label')
                .attr('opacity', 1)
                .call(totalLabelFunc)
                .transition(t)
                .attr('opacity', 1);
            },
            (update) => {
              update.transition(t).call(totalLabelFunc);
            },
            (exit) => {
              exit.transition(t).attr('opacity', 0).remove();
            }
          );
      }
      if (config.enableTopLabels) {
        selected
          .selectAll('.bar-label-group')
          .data(data[data.length - 1])
          .join(
            (enter) => {
              const labelGrp = enter
                .append('g')
                .attr('class', 'bar-label-group')
                .attr('transform', (d, i) => {
                  return `translate(${
                    config.xScale(d.label) + config.xScale.bandwidth() / 2
                  }, ${config.yScale(config.maxY * 1.15)})`;
                });

              labelGrp.call(topLabels, config, t);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          )
          .call(topLabels, config, t);
      }

      const columnRectFunc = function (eleRef) {
        eleRef
          .attr('data-gi', (d) => d.labelIndex)
          .style('fill', (d, i) => {
            if (config.enableTextForSourceAndJournalist) {
              return `url(#gradient-${d.label?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}-${i})`;
            } else if (config.breakDownByMedia || config?.mediaTypeChart) {
              return `url(#gradient-${d.label?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}-${d.labelText?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )})`;
            } else if (config.haveGradient) {
              return `url(#gradient-${d.label?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}-${d.labelText?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )})`;
            } else {
              return d.color;
            }
            // return d[`${d.labelText}BarColor`];
            // return config.barLevelColor
            //   ? d[`${d.labelText}BarColor`]
            //   : config?.enableTextForSourceAndJournalist
            //   ? `url(#gradient-${d.label?.replace(/[\s:;,]/g, '')}-${i})`
            //   :
          })
          .attr('width', (d) =>
            d.accValue === 0
              ? 0
              : config.graphType === 'group'
              ? config.columnWidth - config.columnGroupPadding // ? (config.columnWidth / data.length) - config.columnGroupPadding
              : config.columnWidth
          )

          .attr('x', (d, i) => {
            return config.graphType === 'group'
              ? config.xScale(d.label) +
                  (xWidth - config.columnWidth) / 3 +
                  // d.labelIndex * (config.columnWidth / data.length)
                  d.labelIndex * config.columnWidth
              : config.xScale(d.label) + (xWidth - config.columnWidth) / 2;
          });
      };

      const columnRectHeightFunc = function (eleRef) {
        eleRef
          .attr('y', (d, i) =>
            config.graphType === 'group'
              ? d.value < 0
                ? config.yScale(0)
                : config.yScale(d.value)
              : config.yScale(d.accValue)
          )
          .attr('height', (d, i) => {
            const barHeight =
              config.yScale(0) - config.yScale(parseFloat(d.value));
            return barHeight < 0
              ? -1 * barHeight
              : config.barSpacing
              ? barHeight - 2
              : barHeight;
          });
      };
      // Check for No Data and add text if necessary

      if (config.breakDownByMedia || config.enableTextForSourceAndJournalist) {
        const uniqueNoDataCoordinates = new Set();
        selected
          .selectAll('.bar-group')
          .selectAll('.no-data-text')
          .data(uniqueLabels)
          .join(
            (enter) => {
              enter
                .append('text')
                .attr('class', 'no-data-text')
                .attr('x', (d) =>
                  // config.graphType === 'group'
                  //   ? config.xScale(d.label) +
                  //     (xWidth - config.columnWidth) / 3 +
                  //     // d.labelIndex * (config.columnWidth / data.length)
                  //     d.labelIndex * config.columnWidth
                  //   : config.xScale(d.label) + (xWidth - config.columnWidth) / 2
                  config?.enableTextForSourceAndJournalist
                    ? config.xScale(d) +
                      (xWidth - config.columnWidth) / 2 +
                      10 +
                      // : config.xScale(d.label) + (xWidth - config.columnWidth) / 2
                      // d.labelIndex * (config.columnWidth / data.length)
                      config.columnWidth
                    : config.xScale(d) / 2 || config.graphAreaH / 1.5
                )
                .attr('y', config.graphAreaH / 2) // Adjust the y position as necessary
                .attr('text-anchor', 'start')
                .attr('font-size', config.fontSize)
                .attr('fill', config.fontColor)
                .text('No Data')
                .each(function (d) {
                  const coordKey = `${d3.select(this).attr('x')},${d3
                    .select(this)
                    .attr('y')}`;
                  if (uniqueNoDataCoordinates.has(coordKey)) {
                    d3.select(this).remove();
                  } else {
                    uniqueNoDataCoordinates.add(coordKey);
                  }
                });
            },
            (update) => {
              update
                .attr('x', (d) =>
                  // config.graphType === 'group'
                  //   ? config.xScale(d.label) +
                  //     (xWidth - config.columnWidth) / 3 +
                  //     // d.labelIndex * (config.columnWidth / data.length)
                  //     d.labelIndex * config.columnWidth
                  //   : config.xScale(d.label) + (xWidth - config.columnWidth) / 2
                  config?.enableTextForSourceAndJournalist
                    ? config.xScale(d) +
                      (xWidth - config.columnWidth) / 2 +
                      10 +
                      // : config.xScale(d.label) + (xWidth - config.columnWidth) / 2
                      // d.labelIndex * (config.columnWidth / data.length)
                      config.columnWidth
                    : config.xScale(d) / 2 || config.graphAreaH / 1.5
                )
                .attr('y', config.graphAreaH / 2) // Adjust the y position as necessary
                .attr('text-anchor', 'middle')
                .attr('font-size', config.fontSize)
                .attr('fill', config.fontColor)
                .text('No Data')
                .each(function (d) {
                  const coordKey = `${d3.select(this).attr('x')},${d3
                    .select(this)
                    .attr('y')}`;
                  if (uniqueNoDataCoordinates.has(coordKey)) {
                    d3.select(this).remove();
                  } else {
                    uniqueNoDataCoordinates.add(coordKey);
                  }
                });
            },
            (exit) => {
              exit.remove();
            }
          );
      }

      selected
        .selectAll('.bar-group')
        .selectAll('.column-rect')
        .data(
          (d, i) =>
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            })
          // .filter((d) => d.value !== 0) // Filter out entries with zero value
        )
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'column-rect')
              .attr('rx', config.dashboardType === 'congruence' ? 8 : 4)
              .style('filter', (d, i) => {
                // config?.colorGradient &&
                // (d.value < 0
                //   ? 'url(#drop-shadow-negative)'
                //   : 'url(#drop-shadow-positive)')
                const color = d.color || ColorParser(colorBox[i]);

                if (config.dashboardType === 'congruence') {
                  const rgbaColor = hexToRgba(color, 0.6);
                  return `drop-shadow(0px 10px 4px ${rgbaColor})`;
                } else {
                  const rgbaColor = hexToRgba(color, 0.4);
                  return d.value > 0
                    ? `drop-shadow(0px 4px 6px ${rgbaColor})`
                    : `drop-shadow(0px -4px 6px ${rgbaColor})`;
                }
              })
              .call(columnRectFunc)
              .attr('y', config.height - (config.height - config.graphAreaH))
              .transition(t)
              .call(columnRectHeightFunc);
          },
          (update) => {
            update
              .transition(t)
              .call(columnRectHeightFunc)
              .call(columnRectFunc);
          },
          (exit) => {
            exit.transition(t).attr('height', 0).attr('width', 0).remove();
          }
        );

      if (config.enableTextForSourceAndJournalist) {
        const heightDifferent = () => {
          if (data[0]?.length === 6 && xWidth) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 980) {
              return -5;
            } else if (config?.graphAreaW < 980) {
              return 10;
            }
            return -5;
          } else if (data[0]?.length === 5) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 980) {
              return -10;
            } else if (config?.graphAreaW < 980) {
              return 5;
            }
            return -15;
          } else if (data[0]?.length === 4) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 980) {
              return -20;
            } else if (config?.graphAreaW < 980 && config?.graphAreaW >= 750) {
              return -10;
            } else if (config?.graphAreaW < 750) {
              return 0;
            }
            return -35;
          } else if (data[0]?.length === 3) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 980) {
              return -40;
            } else if (config?.graphAreaW < 980 && config?.graphAreaW >= 750) {
              return -25;
            } else if (config?.graphAreaW < 750) {
              return -10;
            }
            return -55;
          } else if (data[0]?.length === 2) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 1100) {
              return -80;
            } else if (config?.graphAreaW < 1100 && config?.graphAreaW >= 900) {
              return -70;
            } else if (config?.graphAreaW < 900 && config?.graphAreaW >= 650) {
              return -45;
            } else if (config?.graphAreaW < 650) {
              return -20;
            }
            return -100;
          } else if (data[0]?.length === 1) {
            if (config?.graphAreaW < 1300 && config?.graphAreaW >= 1100) {
              return -150;
            } else if (config?.graphAreaW < 1100 && config?.graphAreaW >= 900) {
              return -85;
            } else if (config?.graphAreaW < 900 && config?.graphAreaW >= 650) {
              return -75;
            } else if (config?.graphAreaW < 650) {
              return -40;
            }
            return -245;
          }
        };

        const uniqueCoordinates = new Set();
        selected
          .selectAll('.bar-group')
          .selectAll('.column-label')
          .data((d, i) =>
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            })
          )
          .join(
            (enter) => {
              enter
                .append('text')
                .attr('class', 'column-label')
                .attr('x', (d, i) => {
                  return config.graphType === 'group'
                    ? config.xScale(d.label) +
                        (xWidth - config.columnWidth) / 2 +
                        heightDifferent() +
                        // d.labelIndex * (config.columnWidth / data.length)
                        // config.columnWidth / 2
                        d.labelIndex * config.columnWidth -
                        data[0]?.length * 2
                    : config.xScale(d.label) +
                        (xWidth - config.columnWidth) / 2;
                })
                .attr('y', (d) => config.yScale(d.value) - 5)
                .attr('height', (d, i) => {
                  const barHeight =
                    config.yScale(0) - config.yScale(parseFloat(d.value) + 10);
                  return barHeight < 0 ? -1 * barHeight : barHeight;
                })
                // .attr('height', (d, i) => {
                //   const barHeight =
                //     config.yScale(0) - config.yScale(parseFloat(d.value));
                //   return barHeight < 0 ? -1 * barHeight : barHeight;
                // })
                .attr('dy', '0em') // Offset the label above the rectangle
                .attr('text-anchor', 'start')
                .attr('font-size', config.fontSize)
                .attr('fill', config.fontColor)
                .text((d) => {
                  const text = d.labelText || '';
                  return d.value
                    ? text.length > 12
                      ? `${text.slice(0, 12)}...`
                      : text
                    : '';
                })
                .attr('transform', function (d, i) {
                  const xPos =
                    config.graphType === 'group'
                      ? config.xScale(d.label) +
                        (xWidth - config.columnWidth) / 3 +
                        d.labelIndex * config.columnWidth +
                        config.columnWidth / 2
                      : config.xScale(d.label) + config.xScale.bandwidth() / 2;

                  const yPos = config.yScale(d.value) - 5; // Adjust to position the text above the bar
                  return `rotate(-90, ${xPos}, ${yPos})`;
                })
                .each(function (d) {
                  const coordKey = `${d3.select(this).attr('x')},${d3
                    .select(this)
                    .attr('y')}`;
                  if (uniqueCoordinates.has(coordKey)) {
                    d3.select(this).remove();
                  } else {
                    uniqueCoordinates.add(coordKey);
                  }
                });
            },
            (update) => {
              update
                .attr('x', (d, i) =>
                  config.graphType === 'group'
                    ? config.xScale(d.label) +
                      (xWidth - config.columnWidth) / 2 +
                      heightDifferent() +
                      // d.labelIndex * (config.columnWidth / data.length)
                      // config.columnWidth / 2
                      d.labelIndex * config.columnWidth -
                      data[0]?.length * 2
                    : config.xScale(d.label) + (xWidth - config.columnWidth) / 2
                )
                .attr('y', (d) => config.yScale(d.value) - 5)
                .attr('font-size', config.fontSize)
                .attr('height', (d, i) => {
                  const barHeight =
                    config.yScale(0) - config.yScale(parseFloat(d.value) + 10);
                  return barHeight < 0 ? -1 * barHeight : barHeight;
                })
                // .attr('height', (d, i) => {
                //   const barHeight =
                //     config.yScale(0) - config.yScale(parseFloat(d.value));
                //   return barHeight < 0 ? -1 * barHeight : barHeight;
                // })
                .attr('dy', '0em') // Offset the label above the rectangle
                .text((d) => {
                  const text = d.labelText || '';
                  return d.value
                    ? text.length > 12
                      ? `${text.slice(0, 12)}...`
                      : text
                    : '';
                })
                .attr('transform', function (d, i) {
                  const xPos =
                    config.graphType === 'group'
                      ? config.xScale(d.label) +
                        (xWidth - config.columnWidth) / 3 +
                        d.labelIndex * config.columnWidth +
                        config.columnWidth / 2
                      : config.xScale(d.label) + config.xScale.bandwidth() / 2;

                  const yPos = config.yScale(d.value) - 5; // Adjust to position the text above the bar
                  return `rotate(-90, ${xPos}, ${yPos})`;
                })
                .each(function (d) {
                  const coordKey = `${d3.select(this).attr('x')},${d3
                    .select(this)
                    .attr('y')}`;
                  if (uniqueCoordinates.has(coordKey)) {
                    d3.select(this).remove();
                  } else {
                    uniqueCoordinates.add(coordKey);
                  }
                });
            },
            (exit) => {
              exit.transition(t).attr('height', 0).attr('width', 0).remove();
            }
          );
      }

      if (config.enableRectLabels || config.textLabelFlag) {
        selected.selectAll('.bar-group').call(rectLabels, config, t, xWidth);
      }

      setUpEvents(config, selected, 'column-rect');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val, initialValues);
    return graph;
  };

  return graph;
};
