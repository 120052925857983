import * as d3 from 'd3';
import { topThemeColors as colorBox, initialValues } from '../utils/graphConst';
import { hexToRgba } from '../utils/hexToRgba';
import ColorParser from '../utils/colorParser';
import { setUpEvents } from '../utils/graphEvents';

function checkCollision(x1, y1, r1, x2, y2, r2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.sqrt(dx * dx + dy * dy);
  return distance < r1 + r2;
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export const bubbleSequenceCircles = function bubbleSequenceCircles() {
  let config = {
    ...initialValues,
    keywordCircleRadius: undefined,
  };

  function graph(selected) {
    config.keywordCircleRadius = config.graphAreaW < 1000 ? 25 : 35;
    selected.each(function (data) {
      const svg = d3.select(this);
      const limitedData = data[0]?.slice(0, 6);
      // min and max radius of parent
      const getScaledRadius = (graphWidth) => {
        // Base values for a reference width (e.g., 1000px)
        const baseWidth = 1000;
        const baseMinRadius = 40;
        const baseMaxRadius = config.maxBubbleSize;
        // Calculate the scale factor
        const scaleFactor = graphWidth / baseWidth;

        // Scale the min and max radius
        const minRadius = Math.max(baseMinRadius * scaleFactor, 10); // Ensure minimum radius is not too small
        const maxRadius = Math.min(baseMaxRadius * scaleFactor, 120); // Ensure maximum radius is not too large

        return { minRadius, maxRadius };
      };

      // Use the function
      const graphWidth = config.graphAreaW;

      const { minRadius, maxRadius } = getScaledRadius(graphWidth);

      const maxValue = d3.max(limitedData, (d) => d.value);
      const minValue = d3.min(limitedData, (d) => d.value);

      const radiusScale = d3
        .scaleSqrt()
        .domain([minValue, maxValue])
        .range([minRadius, maxRadius]);

      const totalWidth = limitedData.reduce((acc, d) => {
        return acc + 2 * radiusScale(d.value) + 8;
      }, 0);

      const startX = (config.graphAreaW - totalWidth) / 2;

      let bubblesGroup = svg.select('.bubbles-group');
      if (bubblesGroup.empty()) {
        bubblesGroup = svg.append('g').attr('class', 'bubbles-group');
      }

      const bubbleUpdate = bubblesGroup
        .selectAll('.bubble-grp')
        .data(limitedData, (d) => d.label);

      bubbleUpdate
        .exit()
        .transition()
        .duration(config.duration)
        .style('opacity', 0)
        .remove();

      const bubbleEnter = bubbleUpdate
        .enter()
        .append('g')
        .attr('class', 'bubble-grp')
        .attr('transform', (d, i) => {
          let currentX = startX;
          for (let j = 0; j < i; j++) {
            currentX += 2 * radiusScale(limitedData[j].value) + 8;
          }
          const cx = currentX + radiusScale(d.value);
          return `translate(${cx},${config.graphAreaH / 2})`;
        })
        .style('opacity', 0);

      bubbleEnter.append('circle').attr('class', 'parent-circle');
      bubbleEnter.append('text').attr('class', 'label-text');
      bubbleEnter.append('text').attr('class', 'value-text');

      const bubbleMerge = bubbleUpdate.merge(bubbleEnter);

      bubbleMerge
        .transition()
        .duration(config.duration)
        .style('opacity', 1)
        .attr('transform', (d, i) => {
          let currentX = startX;
          for (let j = 0; j < i; j++) {
            currentX += 2 * radiusScale(limitedData[j].value) + 8;
          }
          const cx = currentX + radiusScale(d.value);
          return `translate(${cx + 30},${config.graphAreaH / 2})`;
        });

      bubbleMerge
        .select('.parent-circle')
        .transition()
        .duration(config.duration)
        .attr('r', (d) => radiusScale(d.value))
        .attr('fill', (d, i) => {
          d.color = ColorParser(colorBox[i % colorBox.length]); // Store the color in the data
          return d.color;
        })
        .attr('filter', (d) => {
          const rgbaColor = hexToRgba(d.color, 0.4);
          return `drop-shadow(0px 20px 17px ${rgbaColor})`;
        });

      bubbleMerge
        .select('.label-text')
        .attr('y', -10)
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .attr('font-size', (d) =>
          radiusScale(d.value) >= 140 ? '16px' : '13px'
        )
        .attr('font-weight', 500)
        .attr('fill', 'white')
        .each(function (d) {
          const radius = radiusScale(d.value);
          const maxChars = Math.floor(radius / 6);
          if (maxChars < 4) {
            d3.select(this).text('');
            return;
          }

          if (radius > 80) {
            const words = d.label.split(/\s+/);
            let line1 = '';
            let line2 = '';

            for (const word of words) {
              if ((line1 + word).length <= maxChars) {
                line1 += (line1 ? ' ' : '') + word;
              } else if ((line2 + word).length <= maxChars) {
                line2 += (line2 ? ' ' : '') + word;
              } else {
                break;
              }
            }

            if (line2) {
              d3.select(this)
                .text(null)
                .append('tspan')
                .attr('x', 0)
                .attr('dy', '-0.6em')
                .text(line1)
                .append('tspan')
                .attr('x', 0)
                .attr('dy', '1.2em')
                .text(line2);
            } else {
              d3.select(this).text(line1);
            }
          } else {
            // Previous logic for smaller circles
            d3.select(this).text(
              d.label.length > maxChars
                ? `${d.label.slice(0, maxChars - 1)}…`
                : d.label
            );
          }
        });

      bubbleMerge
        .select('.value-text')
        .attr('y', function (d) {
          const radius = radiusScale(d.value);
          if (radius > 80) {
            const labelText = d3.select(this.parentNode).select('.label-text');
            return labelText.node().getBBox().height / 2 + 10;
          }
          return 10; // Previous y-value for smaller circles
        })
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .attr('font-size', (d) =>
          radiusScale(d.value) >= 140 ? '24px' : '18px'
        )
        .attr('font-weight', 600)
        .attr('fill', 'white')
        .text((d) => {
          const radius = radiusScale(d.value);
          const maxChars = Math.floor(radius / 6);
          if (maxChars < 4) return '';
          const value = d.value.toString();
          return value.length > maxChars
            ? `${value.slice(0, maxChars - 1)}…`
            : parseInt(value)?.toLocaleString('en-US');
        });
      // Calculate positions for all parent circles
      const parentPositions = limitedData.map((d, i) => {
        let currentX = startX;
        for (let j = 0; j < i; j++) {
          currentX += 2 * radiusScale(limitedData[j].value) + 8;
        }
        const cx = currentX + radiusScale(d.value);
        return {
          x: cx,
          y: config.graphAreaH / 2,
          radius: radiusScale(d.value),
        };
      });

      // Store all keyword positions
      const allKeywordPositions = [];

      // Add keyword circles
      bubbleMerge.each(function (d, i) {
        const parentGroup = d3.select(this);
        const parentRadius = radiusScale(d.value);
        const parentPosition = parentPositions[i];

        // Determine how many keywords to show based on parent circle size
        const maxKeywords = Math.min(
          5,
          Math.max(0, Math.floor(parentRadius / 20))
        );
        const keywordData = d.keyword.slice(0, maxKeywords);

        const keywordCircles = parentGroup
          .selectAll('.keyword-circle')
          .data(keywordData);

        keywordCircles.exit().remove();

        const keywordEnter = keywordCircles
          .enter()
          .append('g')
          .attr('class', 'keyword-circle')
          .style('opacity', 0);

        keywordEnter
          .append('circle')
          .attr('r', config.keywordCircleRadius)
          .attr('fill', d.color) // Use the parent's color
          .attr('filter', () => {
            const rgbaColor = hexToRgba(d.color, 0.6); // Use the parent's color
            return `drop-shadow(0px 20px 17px ${rgbaColor})`;
          })
          .style('opacity', 0.7);

        keywordEnter
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '.35em')
          .attr('font-size', '10px')
          .style('pointer-events', 'none')
          .attr('fill', 'white')
          .text((d) => {
            const radius = config.keywordCircleRadius;
            const maxChars = Math.floor(radius / 6);
            if (maxChars < 4) return '';
            return d.length > maxChars ? `${d.slice(0, maxChars - 1)}…` : d;
          });

        const keywordMerge = keywordCircles.merge(keywordEnter);

        keywordMerge
          .select('circle')
          .transition() // Add this line
          .duration(config.duration) // Add this line
          .attr('r', config.keywordCircleRadius) // Add this
          .attr('fill', parentGroup.datum().color) // Ensure update with parent's color
          .attr('filter', () => {
            const rgbaColor = hexToRgba(parentGroup.datum().color, 0.6); // Use the parent's color
            return `drop-shadow(0px 20px 17px ${rgbaColor})`;
          });

        keywordMerge.attr('transform', (_, keywordIndex) => {
          const baseAngle = (keywordIndex / keywordData.length) * 2 * Math.PI;
          let angle = baseAngle;
          let x, y;
          let collisionFound = true;
          let attempts = 0;
          const maxAttempts = 50;

          while (collisionFound && attempts < maxAttempts) {
            const distance = parentRadius + config.keywordCircleRadius * 1.3;
            x = Math.cos(angle) * distance;
            y = Math.sin(angle) * distance;

            // Clamp the position to keep keywords within the visualization area
            x = clamp(
              x,
              -config.graphAreaW / 2 + config.keywordCircleRadius,
              config.graphAreaW / 2 - config.keywordCircleRadius
            );
            y = clamp(
              y,
              -config.graphAreaH / 2 + config.keywordCircleRadius,
              config.graphAreaH / 2 - config.keywordCircleRadius
            );

            collisionFound = parentPositions.some((otherParent, otherIndex) => {
              if (i === otherIndex) return false;
              return checkCollision(
                parentPosition.x + x,
                parentPosition.y + y,
                config.keywordCircleRadius,
                otherParent.x,
                otherParent.y,
                otherParent.radius
              );
            });

            if (!collisionFound) {
              collisionFound = allKeywordPositions.some((pos) =>
                checkCollision(
                  parentPosition.x + x,
                  parentPosition.y + y,
                  config.keywordCircleRadius,
                  pos.x,
                  pos.y,
                  config.keywordCircleRadius
                )
              );
            }

            if (collisionFound) {
              angle += 0.1;
              if (angle >= baseAngle + 2 * Math.PI) {
                angle = baseAngle;
              }
            }

            attempts++;
          }

          // Store the new keyword position
          allKeywordPositions.push({
            x: parentPosition.x + x,
            y: parentPosition.y + y,
          });

          return `translate(${x},${y})`;
        });

        // Define the transition duration
        const transitionDuration = 400; // Duration in milliseconds

        // Update opacity based on showAllSubCircles or parent's hover state
        function updateKeywordOpacity() {
          keywordMerge
            .transition()
            .duration(transitionDuration)
            .style('opacity', (d, i, nodes) => {
              const parent = d3.select(nodes[i].parentNode);
              return config.showAllSubCircles || parent.classed('hovered')
                ? 1
                : 0;
            });
        }

        // Initial opacity setting
        updateKeywordOpacity();

        // Show/hide keywords based on config and hover with animation
        parentGroup
          .on('mouseenter.custom', function () {
            if (!config.showAllSubCircles) {
              d3.select(this).classed('hovered', true);
              updateKeywordOpacity();
            }
          })
          .on('mouseleave.custom', function () {
            if (!config.showAllSubCircles) {
              d3.select(this).classed('hovered', false);
              updateKeywordOpacity();
            }
          });
      });
    });

    // Move setUpEvents outside of the each loop
    setUpEvents(config, selected, 'bubble-grp');

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
