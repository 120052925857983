export const magentaColorGradients = {
  magenta100: '#2A0A18',
  magenta90: '#510224',
  magenta80: '#740937',
  magenta70: '#9F1853',
  magenta60: '#D02670',
  magenta50: '#EE5396',
  magenta40: '#FF7EB6',
  magenta30: '#FFAFD2',
  magenta20: '#FFD6E8',
  magenta10: '#FFF0F7',
};

export const purpleColorGradients = {
  purple100: '#1C0F30',
  purple90: '#31135E',
  purple80: '#491D8B',
  purple70: '#6929C4',
  purple60: '#893FFC',
  purple50: '#A56EFF',
  purple40: '#BD96FE',
  purple30: '#D4BCFF',
  purple20: '#E8DBFF',
  purple10: '#F6F2FF',
};

export const blueColorGradients = {
  blue100: '#001141',
  blue90: '#001D6C',
  blue80: '#002D9C',
  blue70: '#0143CE',
  blue60: '#0E62FE',
  blue50: '#4689FF',
  blue40: '#76AAFE',
  blue30: '#A6C9FF',
  blue20: '#D0E2FE',
  blue10: '#EDF4FF',
};

export const cyanColorGradients = {
  cyan100: '#061727',
  cyan90: '#012749',
  cyan80: '#003A6D',
  cyan70: '#00539A',
  cyan60: '#0072C3',
  cyan50: '#1192E8',
  cyan40: '#33B1FF',
  cyan30: '#82CFFF',
  cyan20: '#BAE6FF',
  cyan10: '#E5F6FF',
};

export const tealColorGradients = {
  teal100: '#081A1C',
  teal90: '#022B30',
  teal80: '#004144',
  teal70: '#005D5D',
  teal60: '#007D79',
  teal50: '#009D9A',
  teal40: '#09BDB9',
  teal30: '#3DDBD9',
  teal20: '#9EF0F0',
  teal10: '#D9FBFB',
};

export const greenColorGradients = {
  green100: '#071908',
  green90: '#022D0D',
  green80: '#044317',
  green70: '#0E6027',
  green60: '#198038',
  green50: '#24A148',
  green40: '#42BE65',
  green30: '#6FDC8C',
  green20: '#A7F0BA',
  green10: '#DEFBE6',
};

export const yellowColorGradients = {
  yellow100: '#1C1500',
  yellow90: '#302400',
  yellow80: '#483700',
  yellow70: '#684E00',
  yellow60: '#8E6A00',
  yellow50: '#B28600',
  yellow40: '#D2A106',
  yellow30: '#F1C21B',
  yellow20: '#FDDC69',
  yellow10: '#FCF4D6',
};

export const orangeColorGradients = {
  orange100: '#231000',
  orange90: '#3E1A00',
  orange80: '#5E2900',
  orange70: '#8A3800',
  orange60: '#BA4E00',
  orange50: '#EB6200',
  orange40: '#FF832B',
  orange30: '#FFB784',
  orange20: '#FFD98E',
  orange10: '#FFF2E8',
};

export const redColorGradients = {
  red100: '#2D0709',
  red90: '#520408',
  red80: '#750E13',
  red70: '#A2191F',
  red60: '#DA1E28',
  red50: '#FA4E56',
  red40: '#FF8389',
  red30: '#FFB3B8',
  red20: '#FFD7D9',
  red10: '#FFF1F1',
};

export const coolGrayColorGradients = {
  coolGray100: '#121619',
  coolGray90: '#21272A',
  coolGray80: '#21272A',
  coolGray70: '#4D5358',
  coolGray60: '#697077',
  coolGray50: '#878D96',
  coolGray40: '#A9ADBE',
  coolGray30: '#C1C7CD',
  coolGray20: '#DDE1E6',
  coolGray10: '#F2F4F8',
};

export const allColorGradients = {
  ...magentaColorGradients,
  ...purpleColorGradients,
  ...blueColorGradients,
  ...cyanColorGradients,
  ...tealColorGradients,
  ...greenColorGradients,
  ...yellowColorGradients,
  ...orangeColorGradients,
  ...redColorGradients,
  ...coolGrayColorGradients,
};
