import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import graphBucket from '../utils/graphBucket';
import { initialValues, colorBox } from '../utils/graphConst';
import { getGraphUtils } from '../utils/graphUtils';
import { getAccArr } from '../CoolColumn/columnUtils';
import { capitalizeFirstWord } from '../../utils';

class BestBarGraph extends RootGraph {
  setData(data) {
    const inData = JSON.parse(JSON.stringify(data));
    this.labels = inData?.labels
      ? inData?.labels
      : [{ label: 'label', value: 'value' }];

    // this.data = inData?.data;
    const mainData = JSON.parse(JSON.stringify(inData?.data));
    const dataLength = mainData.length || 15;
    this.data = mainData.filter(
      (ele, i) => i < (this.config.maxData || dataLength)
    );

    this.filteredData = this.data;
    const formattedData = [];
    // process data object with multiple values
    for (let k = 0; k < this.labels.length; k++) {
      const items = [];
      for (let i = 0; i < this.filteredData.length; i++) {
        const value = this.filteredData[i][this.labels[k].value];
        const item = {
          label: this.config.capitalizeLabel
            ? capitalizeFirstWord(this.filteredData[i].label)
            : this.filteredData[i].label,
          value: parseInt(value),
          accValue:
            (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
              ? formattedData[k - 1][i]?.accValue
              : 0) + parseInt(value),
          labelText: this.labels[k].label,
          index: i,
          color: this.labels[k].color
            ? this.labels[k].color
            : this.data[i].color
            ? this.data[i].color
            : colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
          labelColor: this.filteredData[i]?.labelColor,
          rawData: this.filteredData[i],
          key: inData?.key,
          // color: this.data[i].color ? this.data[i].color : colorBox[k],
        };
        items.push(item);
      }
      formattedData.push(items);
    }
    this.graphData = formattedData;
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    super.drawGraph();
    const data = this.graphData[0];
    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'group1',
      ...this.config,
    };

    const { minY, maxY, graphAreaH, graphAreaW, graphAreaL } = getGraphUtils(
      config,
      config.graphType === 'group'
        ? this.graphData.flat(Infinity)
        : getAccArr(this.graphData)
    );

    const xScale = d3
      .scaleLinear()
      .range([graphAreaW, 0])
      .domain([maxY + (maxY / 100) * 25, minY < 0 ? minY : 0]);

    const xScaleN = d3
      .scaleLinear()
      .range([graphAreaW, 0])
      .domain([maxY + (maxY / 100) * 25, minY < 0 ? minY : 0]);

    const yScale = d3
      .scaleBand()
      .range([config.graphTopPadding, graphAreaH])
      .domain(
        data.map(function (d, i) {
          return d.label;
        })
      );

    config = {
      ...config,
      xScaleN,
      xScale,
      yScale,
      graphAreaH,
      graphAreaW,
      minY,
      maxY,
    };

    // x-axis
    const xAxis = graphBucket.xAxis().config(config);
    this.$graphGrp.datum([data]).call(xAxis);

    // y-axis
    const yAxis = graphBucket.yAxis().config(config);
    this.$graphGrp.datum([data]).call(yAxis);

    //  rect
    const rects = graphBucket.barRect().config(config);

    const rectGrps = this.$graphGrp.selectAll('.barRectGroup').node()
      ? this.$graphGrp.selectAll('.barRectGroup')
      : this.$graphGrp.append('g');

    rectGrps
      .attr('class', 'barRectGroup')
      .attr('transform', 'translate(' + graphAreaL + ',0)')
      .datum(this.graphData)
      .call(rects);
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.column-rect');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
  }
}

export default BestBarGraph;
