export const oneD = [
  { label: 'a', value: 10, color: '#58D140' },
  { label: 'b', value: 15, color: '#58D140' },
  { label: 'c', value: 8, color: '#58D140' },
  { label: 'd', value: 17, color: '#58D140' },
  { label: 'e', value: 12, color: '#58D140' },
  { label: 'f', value: 20, color: '#58D140' },
  { label: 'g', value: 20, color: '#58D140' },
  { label: 'h', value: 20, color: '#58D140' },
  { label: 'i', value: 20, color: '#58D140' },
  { label: 'j', value: 20, color: '#58D140' },
  { label: 'k', value: 20, color: '#58D140' },
  { label: 'l', value: 20, color: '#58D140' },
];

export const oneD1 = [
  { label: 'a', value: 10, color: '#58D140' },
  { label: 'b', value: 15, color: '#58D140' },
  { label: 'c', value: 8, color: '#58D140' },
  { label: 'd', value: 17, color: '#58D140' },
  { label: 'e', value: 12, color: '#58D140' },
  { label: 'f', value: 20, color: '#58D140' },
  { label: 'g', value: 8, color: '#58D140' },
  { label: 'h', value: 20, color: '#58D140' },
  { label: 'i', value: 20, color: '#58D140' },
  { label: 'j', value: 12, color: '#58D140' },
  { label: 'k', value: 22, color: '#58D140' },
  { label: 'l', value: 10, color: '#58D140' },
];

export const twoD = [
  { label: 'a', x: 2, y: 2, z: 3 },
  { label: 'b', x: 2, y: 2, z: 3 },
  { label: 'c', x: 2, y: 2, z: 3 },
  { label: 'd', x: 2, y: 2, z: 3 },
  { label: 'e', x: 2, y: 2, z: 3 },
  { label: 'f', x: 2, y: 2, z: 3 },
  { label: 'g', x: 2, y: 2, z: 3 },
  { label: 'h', x: 2, y: 2, z: 3 },
  { label: 'i', x: 2, y: 2, z: 3 },
  { label: 'j', x: 2, y: 2, z: 3 },
  { label: 'k', x: 2, y: 2, z: 3 },
  { label: 'l', x: 2, y: 2, z: 3 },
];

export const actualOneD = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: { label: '55%', subLabel: '132/237' },
  data: [
    { label: 'SD', value: 20, thresholdValue: 80, color: '#58CF6B' },
    { label: 'D', value: 30, thresholdValue: 20, color: '#FFBA69' },
    { label: 'N', value: 40, thresholdValue: 60, color: '#FD708B' },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const worldMapData = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: { label: '55%', subLabel: '132/237' },
  data: [
    { label: 'India', value: 'IND' },
    { label: 'United States', value: 'USA' },
    { label: 'Australia', value: 'AUS' },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const actualOneD0 = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: {
    subtext: '',
    value: '182',
    thresholdArr: [{ label: 'threshold1', value: '35' }],
  },
  data: [
    { label: 'SD', value: 10 },
    { label: 'D', value: 40 },
    { label: 'N', value: 100 },
    { label: 'A', value: 70 },
    { label: 'SA', value: 15 },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
      color: '#8676FF33',
    },
  ],
  info: [],
};

export const actualOneDG = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: { label: '55%', subLabel: '132/237' },
  data: [
    { label: 'SD1', value: 80 },
    { label: 'threshold1', value: '90', halfRadius: true, arcRadius: 0.5 },
    { label: 'SD', value: 80, halfRadius: true, arcRadius: 0.5 },
    { label: 'SD2', value: 50 },
    { label: 'SD3', value: 80 },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const actualOneDG1 = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: {
    subtext: '',
    value: '182',
  },
  data: [{ label: 'D', value: 40 }],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const actualOneD1 = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: {
    subtext: '',
    value: '182',
  },
  data: [
    { label: 'SD', value: 20 },
    { label: 'D', value: 30 },
    { label: 'N', value: 40 },
    { label: 'A', value: 30 },
    { label: 'SA', value: 20 },
    { label: 'a', value: 10, color: '#58D140' },
    { label: 'b', value: 15, color: '#58D140' },
    { label: 'c', value: 8, color: '#58D140' },
    { label: 'd', value: 17, color: '#58D140' },
    { label: 'e', value: 12, color: '#58D140' },
    { label: 'f', value: 20, color: '#58D140' },
    { label: 'g', value: 20, color: '#58D140' },
    { label: 'h', value: 20, color: '#58D140' },
    { label: 'i', value: 20, color: '#58D140' },
    { label: 'j', value: 20, color: '#58D140' },
    { label: 'k', value: 20, color: '#58D140' },
    { label: 'l', value: 20, color: '#58D140' },
    { label: 'aa', value: 10, color: '#58D140' },
    { label: 'ab', value: 15, color: '#58D140' },
    { label: 'ac', value: 8, color: '#58D140' },
    { label: 'ad', value: 17, color: '#58D140' },
    { label: 'ae', value: 12, color: '#58D140' },
    { label: 'af', value: 20, color: '#58D140' },
    { label: 'ag', value: 20, color: '#58D140' },
    { label: 'ah', value: 20, color: '#58D140' },
    { label: 'ai', value: 20, color: '#58D140' },
    { label: 'aj', value: 20, color: '#58D140' },
    { label: 'ak', value: 20, color: '#58D140' },
    { label: 'al', value: 20, color: '#58D140' },
    { label: 'ba', value: 10, color: '#58D140' },
    { label: 'bb', value: 15, color: '#58D140' },
    { label: 'bc', value: 8, color: '#58D140' },
    { label: 'bd', value: 17, color: '#58D140' },
    { label: 'be', value: 12, color: '#58D140' },
    { label: 'bf', value: 20, color: '#58D140' },
    { label: 'bg', value: 20, color: '#58D140' },
    { label: 'bh', value: 20, color: '#58D140' },
    { label: 'bi', value: 20, color: '#58D140' },
    { label: 'bj', value: 20, color: '#58D140' },
    { label: 'bk', value: 20, color: '#58D140' },
    { label: 'bl', value: 20, color: '#58D140' },
    { label: 'ca', value: 10, color: '#58D140' },
    { label: 'cb', value: 15, color: '#58D140' },
    { label: 'cc', value: 8, color: '#58D140' },
    { label: 'cd', value: 17, color: '#58D140' },
    { label: 'ce', value: 12, color: '#58D140' },
    { label: 'cf', value: 20, color: '#58D140' },
    { label: 'cg', value: 20, color: '#58D140' },
    { label: 'ch', value: 20, color: '#58D140' },
    { label: 'ci', value: 20, color: '#58D140' },
    { label: 'cj', value: 20, color: '#58D140' },
    { label: 'ck', value: 20, color: '#58D140' },
    { label: 'cl', value: 20, color: '#58D140' },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const actualOneD1N = {
  title: 'Defect Distribution',
  subtitle: '2020-04-17',
  summary: {
    subtext: '',
    value: '182',
  },
  data: [
    { label: 'Jan-1', value: 20 },
    { label: 'Jan-5', value: 30 },
    { label: 'Jan-10', value: 40 },
    { label: 'Jan-15', value: 30 },
    { label: 'Jan-20', value: 20 },
    { label: 'Jan-25', value: 10, color: '#00CE75' },
    { label: 'Jan-30', value: 5, color: '#58D140' },
    { label: 'Feb-1', value: 2, color: '#58D140' },
    { label: 'Feb-2', value: -5, color: '#58D140' },
    { label: 'Feb-10', value: -12, color: '#58D140' },
    { label: 'Feb-15', value: -20, color: '#58D140' },
    { label: 'Feb-20', value: -20, color: '#58D140' },
    { label: 'Feb-25', value: -15, color: '#58D140' },
    { label: 'Mar-1', value: -10, color: '#58D140' },
    { label: 'Mar-5', value: -5, color: '#58D140' },
    { label: 'Mar-10', value: 5, color: '#58D140' },
    { label: 'Mar-15', value: 10, color: '#58D140' },
    { label: 'Mar-20', value: 10, color: '#58D140' },
    { label: 'Mar-25', value: 15, color: '#58D140' },
    { label: 'Mar-30', value: 20, color: '#58D140' },
    { label: 'Apr-1', value: 25, color: '#58D140' },
    { label: 'Apr-5', value: 29, color: '#58D140' },
    { label: 'Apr-10', value: 25, color: '#58D140' },
    { label: 'Apr-15', value: 20, color: '#58D140' },
    { label: 'Apr-20', value: 18, color: '#58D140' },
    { label: 'Apr-25', value: 16, color: '#58D140' },
    { label: 'Apr-30', value: 14, color: '#58D140' },
    { label: 'May-1', value: 12, color: '#58D140' },
    { label: 'May-5', value: 10, color: '#58D140' },
    { label: 'May-10', value: 8, color: '#58D140' },
    { label: 'May-15', value: 6, color: '#58D140' },
    { label: 'May-20', value: 4, color: '#58D140' },
    { label: 'May-25', value: 2, color: '#58D140' },
    { label: 'May-30', value: -2, color: '#58D140' },
    { label: 'Jun-1', value: -4, color: '#58D140' },
    { label: 'Jun-5', value: -6, color: '#58D140' },
    { label: 'Jun-10', value: -8, color: '#58D140' },
    { label: 'Jun-15', value: -10, color: '#58D140' },
    { label: 'Jun-20', value: -12, color: '#58D140' },
    { label: 'Jun-25', value: -14, color: '#58D140' },
    { label: 'Jun-30', value: -16, color: '#58D140' },
    { label: 'Jul-1', value: -18, color: '#58D140' },
    { label: 'Jul-5', value: -20, color: '#58D140' },
    { label: 'Jul-10', value: -24, color: '#58D140' },
    { label: 'Jul-15', value: -28, color: '#58D140' },
    { label: 'Jul-20', value: -24, color: '#58D140' },
    { label: 'Jul-25', value: -20, color: '#58D140' },
    { label: 'Jul-30', value: -16, color: '#58D140' },
    { label: 'Aug-1', value: -12, color: '#58D140' },
    { label: 'Aug-5', value: -8, color: '#58D140' },
    { label: 'Aug-10', value: -4, color: '#58D140' },
    { label: 'Aug-15', value: 4, color: '#58D140' },
    { label: 'Aug-20', value: 8, color: '#58D140' },
    { label: 'Aug-25', value: 12, color: '#58D140' },
    { label: 'Aug-30', value: 16, color: '#58D140' },
    { label: 'Sep-1', value: 20, color: '#58D140' },
    { label: 'Sep-5', value: 24, color: '#58D140' },
    { label: 'Sep-10', value: 28, color: '#58D140' },
    { label: 'Sep-15', value: 32, color: '#58D140' },
    { label: 'Sep-20', value: 36, color: '#58D140' },
    { label: 'Sep-25', value: 40, color: '#58D140' },
    { label: 'Sep-30', value: 44, color: '#58D140' },
    { label: 'Oct-1', value: 48, color: '#58D140' },
    { label: 'Oct-5', value: 52, color: '#58D140' },
    { label: 'Oct-10', value: 58, color: '#58D140' },
    { label: 'Oct-15', value: 58, color: '#58D140' },
    { label: 'Oct-20', value: 58, color: '#58D140' },
    { label: 'Oct-25', value: 50, color: '#58D140' },
    { label: 'Oct-30', value: 44, color: '#58D140' },
    { label: 'Nov-1', value: 38, color: '#58D140' },
    { label: 'Nov-5', value: 32, color: '#58D140' },
    { label: 'Nov-10', value: 24, color: '#58D140' },
    { label: 'Nov-15', value: 18, color: '#58D140' },
    { label: 'Nov-20', value: 12, color: '#58D140' },
    { label: 'Nov-25', value: 6, color: '#58D140' },
    { label: 'Nov-30', value: 2, color: '#58D140' },
  ],
  labels: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  info: [],
};

export const actualTwoD = {
  title: 'Grouped Stacked',
  subtitle: '2020-04-17',
  summary: {
    subtext: 'Group Stacked column',
    value: '120',
    widgetName: 'Compliance Categories',
  },
  data: [
    {
      label: 'A',
      low: '49',
      medium: '26',
      high: '34',
      blocker: '10',
      easy: '30',
    },
    {
      label: 'B',
      low: '3',
      medium: '3',
      high: '2',
      blocker: '5',
      easy: '5',
    },
    {
      label: 'C',
      low: '11',
      medium: '6',
      high: '12',
      blocker: '9',
      easy: '10',
    },
    {
      label: 'D',
      low: '6',
      medium: '7',
      high: '10',
      blocker: '8',
      easy: '3',
    },
    {
      label: 'E',
      low: '11',
      medium: '6',
      high: '12',
      blocker: '9',
      easy: '10',
    },
    {
      label: 'F',
      low: '6',
      medium: '7',
      high: '10',
      blocker: '8',
      easy: '3',
    },
    {
      label: 'G',
      low: '11',
      medium: '6',
      high: '12',
      blocker: '9',
      easy: '10',
    },
    {
      label: 'H',
      low: '6',
      medium: '7',
      high: '10',
      blocker: '8',
      easy: '3',
    },
  ],
  labels: [
    {
      label: 'Blocker',
      value: 'blocker',
    },
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Easy',
      value: 'easy',
    },
  ],

  info: [],
};

export const butterflyData = {
  title: 'Sentiment',
  subtitle: '2020-04-17',
  summary: {
    subtext: '',
    value: '',
    widgetName: '',
  },
  data: [
    {
      label: 'Theme 1',
      positive: 40,
      negative: 60,
    },
    {
      label: 'Theme 2',
      positive: 20,
      negative: 80,
    },
    {
      label: 'Theme 3',
      positive: 80,
      negative: 20,
    },
    {
      label: 'Theme 4',
      positive: 75,
      negative: 25,
    },
    {
      label: 'Theme 5',
      positive: 32,
      negative: 68,
    },
  ],
  labels: [
    {
      label: 'Positive',
      value: 'positive',
    },
    {
      label: 'Negative',
      value: 'negative',
    },
  ],

  info: [],
};

export const mediaType = {
  title: 'Media Type',
  subtitle: 'Total Articles',
  summary: {
    subtext: '',
    value: '',
    widgetName: '',
  },
  data: [
    {
      label: 'Jan',
      broadcast: '10',
      print: '16',
      online: '14',
    },
    {
      label: 'Feb',
      broadcast: '15',
      print: '16',
      online: '23',
    },
    {
      label: 'Mar',
      broadcast: '19',
      print: '16',
      online: '24',
    },
    {
      label: 'Apr',
      broadcast: '14',
      print: '16',
      online: '18',
    },
    {
      label: 'May',
      broadcast: '12',
      print: '8',
      online: '14',
    },
    {
      label: 'Jun',
      broadcast: '15',
      print: '8',
      online: '18',
    },
  ],
  labels: [
    {
      label: 'Broadcast',
      value: 'broadcast',
      color: '#8F82FF',
    },
    {
      label: 'Print',
      value: 'print',
      color: '#FF6888',
    },
    {
      label: 'Online',
      value: 'online',
      color: '#FFBE6A',
    },
  ],
  info: [],
};

export const actualTwoD2 = {
  title: 'Grouped Stacked',
  subtitle: '2020-04-17',
  summary: {
    subtext: 'Group Stacked column',
    value: '120',
    widgetName: 'Compliance Categories',
  },
  data: [
    {
      label: 'Apr',
      planned: '0',
      actual: '0',
    },
    {
      label: 'May',
      planned: '0',
      actual: '0',
    },
    {
      label: 'June',
      planned: '0',
      actual: '0',
    },
    {
      label: 'July',
      planned: '1.4',
      actual: '1.98',
    },
    {
      label: 'Aug',
    },
    {
      label: 'Sep',
    },
    {
      label: 'Oct',
    },
    {
      label: 'Nov',
    },
    {
      label: 'Dec',
    },
  ],
  labels: [
    {
      label: 'Planned',
      value: 'planned',
      color: '#8676FF',
      enableDash: true,
    },
    {
      label: 'Baseline',
      value: 'actual',
      color: '#54A0FF',
    },
  ],

  info: [],
};

export const actualTwoD3 = {
  title: 'Grouped Stacked',
  subtitle: '2020-04-17',
  summary: {
    subtext: 'Group Stacked column',
    value: '120',
    widgetName: 'Compliance Categories',
  },
  data: [
    {
      label: 'A',
      low: '10',
      medium: '26',
      medium1: '21',
      high: '60',
    },
    {
      label: 'B',
      low: '20',
      medium: '30',
      medium1: '25',
      high: '70',
    },
    {
      label: 'C',
      low: '15',
      medium: '25',
      medium1: '20',
      high: '50',
    },
    {
      label: 'D',
      low: '5',
      medium: '15',
      medium1: '10',
      high: '80',
    },
    {
      label: 'E',
      low: '20',
      medium: '40',
      medium1: '35',
      high: '60',
    },
    {
      label: 'F',
      low: '30',
      medium: '35',
      medium1: '30',
      high: '70',
    },
    {
      label: 'G',
      low: '20',
      medium: '50',
      medium1: '45',
      high: '60',
    },
    {
      label: 'H',
      low: '15',
      medium: '45',
      medium1: '40',
      high: '70',
    },
  ],
  labels: [
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
    {
      label: 'Medium1',
      value: 'medium1',
    },
    {
      label: 'High',
      value: 'high',
    },
  ],

  info: [],
};

export const actualTwoD4 = {
  title: 'Grouped Stacked',
  subtitle: '2020-04-17',
  summary: {
    subtext: 'Group Stacked column',
    value: '120',
    widgetName: 'Compliance Categories',
  },
  data: [
    {
      label: 'A',
      low: '39',
      medium: '61',
      color: '#ff0000',
    },
    {
      label: 'B',
      low: '45',
      medium: '55',
      color: '#00ff00',
    },
    {
      label: 'C',
      low: '14',
      medium: '86',
      color: '#ff0000',
    },
    {
      label: 'D',
      low: '6',
      medium: '94',
      color: '#00ff00',
    },
    {
      label: 'E',
      low: '11',
      medium: '89',
      color: '#ff0000',
    },
  ],
  labels: [
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
  ],

  info: [],
};

export const actualTwoD5 = {
  title: 'Radial',
  subtitle: '2020-04-17',
  summary: {
    subtext: 'Group Stacked column',
    value: '120',
    widgetName: 'Compliance Categories',
  },
  data: [
    {
      label: 'A',
      low: '4.33',
      medium: '35.6',
      color: '#FF6B6B',
    },
    {
      label: 'B',
      low: '12.33',
      medium: '32',
      color: '#FF6B6B',
    },
    {
      label: 'C',
      low: '8.33',
      medium: '23',
      color: '#FF6B6B',
    },
    {
      label: 'D',
      low: '8.33',
      medium: '60',
      color: '#FF6B6B',
    },
    {
      label: 'E',
      low: '8.33',
      medium: '52',
      color: '#FF6B6B',
    },
    {
      label: 'F',
      low: '8.33',
      medium: '61',
      color: '#FF6B6B',
    },
    {
      label: 'F',
      low: '8.33',
      medium: '85',
      color: '#FF6B6B',
    },
    {
      label: 'E',
      low: '8.33',
      medium: '90',
      color: '#2DB6F5',
    },
    {
      label: 'D',
      low: '8.33',
      medium: '88',
      color: '#FF6B6B',
    },
    {
      label: 'C',
      low: '8.33',
      medium: '60',
      color: '#2DB6F5',
    },
    {
      label: 'K',
      low: '8.33',
      medium: '85',
      color: '#2DB6F5',
    },
    {
      label: 'L',
      low: '8.33',
      medium: '91',
      color: '#2DB6F5',
    },
  ],
  labels: [
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
  ],

  info: [],
};
export const lineTest = {
  title: 'Risks',
  subtitle: '',
  summary: [{ label: '60%', subLabel: '143/237' }],
  data: [
    { label: '11/12', high: 100, low: '60' },
    { label: '11/19', high: 90, low: '50', color: 'red' },
    { label: '26/11', high: 75, low: '30', color: 'red' },
    { label: '03/12', high: 60, low: '30', color: 'red' },
  ],
  labels: [
    {
      label: 'High',
      value: 'high',
      color: 'transparent',
      color1: 'transparent',
      enableDash: true,
      dashLineColor: 'green',
    },
    {
      label: 'Low',
      value: 'low',
      color: '#ff8989',
      colorOpacity: 0.5,
      colorOpacity1: 0.5,
    },
  ],
  info: [],
};

export const lineTest1 = {
  title: 'Risks',
  subtitle: '',
  summary: [{ label: '60%', subLabel: '143/237' }],
  data: [
    { label: '11/12', high: 110, low: '40' },
    { label: '11/19', high: 80, low: '50' },
    { label: '26/11', high: 115, low: '20' },
    { label: '03/12', high: 60, low: '50' },
  ],
  labels: [
    { label: 'High', value: 'high' },
    { label: 'Low', value: 'low' },
  ],
  info: [],
};

export const heatData = {
  data: [
    {
      label: 'feb 6',
      '12 pm': '15',
      '11 pm': '10',
      '9 pm': '12',
      '8 pm': '0',
      '7 pm': '15',
      '6 pm': '34',
      '5 pm': '10',
      '4 pm': '10',
      '3 pm': '10',
      '2 pm': '12',
      '1 pm': '0',
      '11 am': '34',
      '10 am': '10',
      '9 am': '12',
      '8 am': '10',
      '7 am': '10',
      '6 am': '12',
      '5 am': '0',
      '4 am': '15',
      '3 am': '34',
      '2 am': '10',
      '1 am': '12',
      '00 am': '12',
    },
    {
      label: 'feb 7',
      '12 pm': '23',
      '11 pm': '55',
      '9 pm': '2',
      '8 pm': '14',
      '7 pm': '23',
      '6 pm': '56',
      '5 pm': '55',
      '4 pm': '20',
      '3 pm': '55',
      '2 pm': '2',
      '1 pm': '14',
      '11 am': '56',
      '10 am': '55',
      '9 am': '2',
      '8 am': '20',
      '7 am': '55',
      '6 am': '2',
      '5 am': '14',
      '4 am': '23',
      '3 am': '56',
      '2 am': '55',
      '1 am': '2',
      '00 am': '2',
    },
    {
      label: 'feb 8',
      '12 pm': '45',
      '11 pm': '66',
      '9 pm': '25',
      '8 pm': '5',
      '7 pm': '45',
      '6 pm': '78',
      '5 pm': '66',
      '4 pm': '21',
      '3 pm': '66',
      '2 pm': '25',
      '1 pm': '5',
      '11 am': '78',
      '10 am': '66',
      '9 am': '25',
      '8 am': '21',
      '7 am': '66',
      '6 am': '25',
      '5 am': '5',
      '4 am': '45',
      '3 am': '78',
      '2 am': '66',
      '1 am': '25',
      '00 am': '25',
    },
    {
      label: 'feb 9',
      '12 pm': '90',
      '11 pm': '77',
      '9 pm': '15',
      '8 pm': '11',
      '7 pm': '90',
      '6 pm': '23',
      '5 pm': '77',
      '4 pm': '12',
      '3 pm': '77',
      '2 pm': '15',
      '1 pm': '11',
      '11 am': '23',
      '10 am': '77',
      '9 am': '15',
      '8 am': '12',
      '7 am': '77',
      '6 am': '15',
      '5 am': '11',
      '4 am': '90',
      '3 am': '23',
      '2 am': '77',
      '1 am': '15',
      '00 am': '15',
    },
    {
      label: 'feb 10',
      '12 pm': '76',
      '11 pm': '89',
      '9 pm': '1',
      '8 pm': '2',
      '7 pm': '76',
      '6 pm': '145',
      '5 pm': '89',
      '4 pm': '0',
      '3 pm': '89',
      '2 pm': '1',
      '1 pm': '2',
      '11 am': '145',
      '10 am': '89',
      '9 am': '1',
      '8 am': '0',
      '7 am': '89',
      '6 am': '1',
      '5 am': '2',
      '4 am': '76',
      '3 am': '145',
      '2 am': '89',
      '1 am': '1',
      '00 am': '1',
    },
    {
      label: 'feb 11',
      '12 pm': '89',
      '11 pm': '10',
      '9 pm': '100',
      '8 pm': '12',
      '7 pm': '89',
      '6 pm': '156',
      '5 pm': '10',
      '4 pm': '5',
      '3 pm': '10',
      '2 pm': '100',
      '1 pm': '12',
      '11 am': '156',
      '10 am': '10',
      '9 am': '100',
      '8 am': '5',
      '7 am': '10',
      '6 am': '100',
      '5 am': '12',
      '4 am': '89',
      '3 am': '156',
      '2 am': '10',
      '1 am': '100',
      '00 am': '100',
    },
    {
      label: 'feb 12',
      '12 pm': '67',
      '11 pm': '23',
      '9 pm': '78',
      '8 pm': '45',
      '7 pm': '67',
      '6 pm': '167',
      '5 pm': '23',
      '4 pm': '7',
      '3 pm': '23',
      '2 pm': '78',
      '1 pm': '45',
      '11 am': '167',
      '10 am': '23',
      '9 am': '78',
      '8 am': '7',
      '7 am': '23',
      '6 am': '78',
      '5 am': '45',
      '4 am': '67',
      '3 am': '167',
      '2 am': '23',
      '1 am': '78',
      '00 am': '78',
    },
    {
      label: 'feb 13',
      '12 pm': '56',
      '11 pm': '54',
      '9 pm': '12',
      '8 pm': '13',
      '7 pm': '56',
      '6 pm': '167',
      '5 pm': '54',
      '4 pm': '50',
      '3 pm': '54',
      '2 pm': '12',
      '1 pm': '13',
      '11 am': '167',
      '10 am': '54',
      '9 am': '12',
      '8 am': '50',
      '7 am': '54',
      '6 am': '12',
      '5 am': '13',
      '4 am': '56',
      '3 am': '167',
      '2 am': '54',
      '1 am': '12',
      '00 am': '12',
    },
    {
      label: 'feb 14',
      '12 pm': '89',
      '11 pm': '67',
      '9 pm': '12',
      '8 pm': '12',
      '7 pm': '89',
      '6 pm': '178',
      '5 pm': '67',
      '4 pm': '77',
      '3 pm': '67',
      '2 pm': '12',
      '1 pm': '12',
      '11 am': '178',
      '10 am': '67',
      '9 am': '12',
      '8 am': '77',
      '7 am': '67',
      '6 am': '12',
      '5 am': '12',
      '4 am': '89',
      '3 am': '178',
      '2 am': '67',
      '1 am': '12',
      '00 am': '12',
    },
    {
      label: 'feb 15',
      '12 pm': '10',
      '11 pm': '12',
      '9 pm': '10',
      '8 pm': '10',
      '7 pm': '10',
      '6 pm': '10',
      '5 pm': '10',
      '4 pm': '10',
      '3 pm': '10',
      '2 pm': '10',
      '1 pm': '10',
      '11 am': '10',
      '10 am': '10',
      '9 am': '10',
      '8 am': '10',
      '7 am': '10',
      '6 am': '10',
      '5 am': '10',
      '4 am': '10',
      '3 am': '10',
      '2 am': '10',
      '1 am': '10',
      '00 am': '10',
    },
    {
      label: 'feb 16',
      '12 pm': '55',
      '11 pm': '2',
      '9 pm': '55',
      '8 pm': '55',
      '7 pm': '55',
      '6 pm': '55',
      '5 pm': '55',
      '4 pm': '55',
      '3 pm': '55',
      '2 pm': '55',
      '1 pm': '55',
      '11 am': '55',
      '10 am': '55',
      '9 am': '55',
      '8 am': '55',
      '7 am': '55',
      '6 am': '55',
      '5 am': '55',
      '4 am': '55',
      '3 am': '55',
      '2 am': '55',
      '1 am': '55',
      '00 am': '55',
    },
    {
      label: 'feb 17',
      '12 pm': '66',
      '11 pm': '25',
      '9 pm': '66',
      '8 pm': '66',
      '7 pm': '66',
      '6 pm': '66',
      '5 pm': '66',
      '4 pm': '66',
      '3 pm': '66',
      '2 pm': '66',
      '1 pm': '66',
      '11 am': '66',
      '10 am': '66',
      '9 am': '66',
      '8 am': '66',
      '7 am': '66',
      '6 am': '66',
      '5 am': '66',
      '4 am': '66',
      '3 am': '66',
      '2 am': '66',
      '1 am': '66',
      '00 am': '66',
    },
    {
      label: 'feb 18',
      '12 pm': '167',
      '11 pm': '15',
      '9 pm': '167',
      '8 pm': '167',
      '7 pm': '167',
      '6 pm': '167',
      '5 pm': '167',
      '4 pm': '167',
      '3 pm': '167',
      '2 pm': '167',
      '1 pm': '167',
      '11 am': '167',
      '10 am': '167',
      '9 am': '167',
      '8 am': '167',
      '7 am': '167',
      '6 am': '167',
      '5 am': '167',
      '4 am': '167',
      '3 am': '167',
      '2 am': '167',
      '1 am': '167',
      '00 am': '167',
    },
    {
      label: 'feb 19',
      '12 pm': '89',
      '11 pm': '1',
      '9 pm': '89',
      '8 pm': '89',
      '7 pm': '89',
      '6 pm': '89',
      '5 pm': '89',
      '4 pm': '89',
      '3 pm': '89',
      '2 pm': '89',
      '1 pm': '89',
      '11 am': '89',
      '10 am': '89',
      '9 am': '89',
      '8 am': '89',
      '7 am': '89',
      '6 am': '89',
      '5 am': '89',
      '4 am': '89',
      '3 am': '89',
      '2 am': '89',
      '1 am': '89',
      '00 am': '89',
    },
    {
      label: 'feb 20',
      '12 pm': '10',
      '11 pm': '100',
      '9 pm': '10',
      '8 pm': '10',
      '7 pm': '10',
      '6 pm': '10',
      '5 pm': '10',
      '4 pm': '10',
      '3 pm': '10',
      '2 pm': '10',
      '1 pm': '10',
      '11 am': '10',
      '10 am': '10',
      '9 am': '10',
      '8 am': '10',
      '7 am': '10',
      '6 am': '10',
      '5 am': '10',
      '4 am': '10',
      '3 am': '10',
      '2 am': '10',
      '1 am': '10',
      '00 am': '10',
    },
    {
      label: 'feb 21',
      '12 pm': '23',
      '11 pm': '78',
      '9 pm': '23',
      '8 pm': '23',
      '7 pm': '23',
      '6 pm': '23',
      '5 pm': '23',
      '4 pm': '23',
      '3 pm': '23',
      '2 pm': '23',
      '1 pm': '23',
      '11 am': '23',
      '10 am': '23',
      '9 am': '23',
      '8 am': '23',
      '7 am': '23',
      '6 am': '23',
      '5 am': '23',
      '4 am': '23',
      '3 am': '23',
      '2 am': '23',
      '1 am': '23',
      '00 am': '23',
    },
    {
      label: 'feb 22',
      '12 pm': '54',
      '11 pm': '12',
      '9 pm': '54',
      '8 pm': '54',
      '7 pm': '54',
      '6 pm': '54',
      '5 pm': '54',
      '4 pm': '54',
      '3 pm': '54',
      '2 pm': '54',
      '1 pm': '54',
      '11 am': '54',
      '10 am': '54',
      '9 am': '54',
      '8 am': '54',
      '7 am': '54',
      '6 am': '54',
      '5 am': '54',
      '4 am': '54',
      '3 am': '54',
      '2 am': '54',
      '1 am': '54',
      '00 am': '54',
    },
    {
      label: 'feb 23',
      '12 pm': '67',
      '11 pm': '12',
      '9 pm': '67',
      '8 pm': '67',
      '7 pm': '67',
      '6 pm': '67',
      '5 pm': '67',
      '4 pm': '67',
      '3 pm': '67',
      '2 pm': '67',
      '1 pm': '67',
      '11 am': '67',
      '10 am': '67',
      '9 am': '67',
      '8 am': '67',
      '7 am': '67',
      '6 am': '67',
      '5 am': '67',
      '4 am': '67',
      '3 am': '67',
      '2 am': '67',
      '1 am': '67',
      '00 am': '67',
    },
    {
      label: 'feb 24',
      '12 pm': '12',
      '11 pm': '78',
      '9 pm': '12',
      '8 pm': '12',
      '7 pm': '12',
      '6 pm': '14',
      '5 pm': '12',
      '4 pm': '12',
      '3 pm': '16',
      '2 pm': '12',
      '1 pm': '12',
      '11 am': '12',
      '10 am': '12',
      '9 am': '12',
      '8 am': '12',
      '7 am': '18',
      '6 am': '12',
      '5 am': '12',
      '4 am': '12',
      '3 am': '12',
      '2 am': '12',
      '1 am': '12',
      '00 am': '12',
    },
    {
      label: 'feb 25',
      '9 pm': '2',
      '8 pm': '2',
      '7 pm': '2',
      '6 pm': '2',
      '5 pm': '2',
      '4 pm': '2',
      '3 pm': '2',
      '2 pm': '2',
      '1 pm': '2',
      '12 pm': '2',
      '11 am': '2',
      '10 am': '2',
      '9 am': '2',
      '8 am': '2',
      '7 am': '2',
      '6 am': '2',
      '5 am': '2',
      '4 am': '2',
      '3 am': '2',
      '2 am': '2',
      '1 am': '2',
      '00 am': '2',
      '11 pm': '0',
    },
    {
      label: 'feb 26',
      '9 pm': '77',
      '8 pm': '77',
      '7 pm': '77',
      '6 pm': '77',
      '5 pm': '30',
      '4 pm': '77',
      '3 pm': '77',
      '2 pm': '77',
      '1 pm': '77',
      '12 pm': '77',
      '11 am': '77',
      '10 am': '77',
      '9 am': '25',
      '8 am': '77',
      '7 am': '77',
      '6 am': '77',
      '5 am': '77',
      '4 am': '77',
      '3 am': '22',
      '2 am': '77',
      '1 am': '77',
      '00 am': '77',
      '11 pm': '0',
    },
  ],
  summary: {
    value: 26170,
  },
  info: [],
  labels: [
    {
      label: '00 am',
      value: '00 am',
    },
    {
      label: '1 am',
      value: '1 am',
    },
    {
      label: '2 am',
      value: '2 am',
    },
    {
      label: '3 am',
      value: '3 am',
    },
    {
      label: '4 am',
      value: '4 am',
    },
    {
      label: '5 am',
      value: '5 am',
    },
    {
      label: '6 am',
      value: '6 am',
    },
    {
      label: '7 am',
      value: '7 am',
    },
    {
      label: '8 am',
      value: '8 am',
    },
    {
      label: '9 am',
      value: '9 am',
    },
    {
      label: '10 am',
      value: '10 am',
    },
    {
      label: '11 am',
      value: '11 am',
    },
    {
      label: '12 pm',
      value: '12 pm',
    },
    {
      label: '1 pm',
      value: '1 pm',
    },
    {
      label: '2 pm',
      value: '2 pm',
    },
    {
      label: '3 pm',
      value: '3 pm',
    },
    {
      label: '4 pm',
      value: '4 pm',
    },
    {
      label: '5 pm',
      value: '5 pm',
    },
    {
      label: '6 pm',
      value: '6 pm',
    },
    {
      label: '7 pm',
      value: '7 pm',
    },
    {
      label: '8 pm',
      value: '8 pm',
    },
    {
      label: '9 pm',
      value: '9 pm',
    },
    // {
    //   label: "10 pm",
    //   value: "10 pm",
    // },
    {
      label: '11 pm',
      value: '11 pm',
    },
  ],
};

export const heatData1 = {
  data: [
    {
      label: '9-10',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '1 0',
      '>5': '3 0',
    },
    {
      label: '7-8',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '1 0',
      '>=5 <10': '2 0',
      '>5': '2 0',
    },
    {
      label: '5-6',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '0 0',
      '>5': '1 0',
    },
    {
      label: '3-4',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '3 0',
      '>5': '1 0',
    },
    {
      label: '1-2',
      '>=50DisplayLabel': 'test',
      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '2 0',
      '>5': '0 0',
    },
  ],
  summary: {
    value: 26170,
  },
  info: [],
  labels: [
    {
      label: '>5',
      value: '>5',
    },
    {
      label: '>=5 <10',
      value: '>=5 <10',
    },
    {
      label: '>=10 <25',
      value: '>=10 <25',
    },
    {
      label: '>=25 <50',
      value: '>=25 <50',
    },
    {
      label: '>=50',
      value: '>=50',
    },
  ],
};

export const heatMapData = {
  data: [
    {
      label: '10',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '9',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '8',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '7',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '6',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '5',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '4',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '3',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '2',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
    {
      label: '1',
      Z: '2',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '3',
      T: '0',
      S: '0',
      R: '0',
      Q: '1',
      P: '3',
      O: '0',
      N: '0',
      M: '0',
      L: '1',
      K: '3',
      J: '0',
      I: '0',
      H: '0',
      G: '1',
      F: '3',
      E: '0',
      D: '0',
      C: '0',
      B: '1',
      A: '3',
    },
  ],
  summary: {
    value: 26170,
  },
  info: [],
  labels: [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
    {
      label: 'D',
      value: 'D',
    },
    {
      label: 'E',
      value: 'E',
    },
    {
      label: 'F',
      value: 'F',
    },
    {
      label: 'G',
      value: 'G',
    },
    {
      label: 'H',
      value: 'H',
    },
    {
      label: 'I',
      value: 'I',
    },
    {
      label: 'J',
      value: 'J',
    },
    {
      label: 'K',
      value: 'K',
    },
    {
      label: 'L',
      value: 'L',
    },
    {
      label: 'M',
      value: 'M',
    },
    {
      label: 'N',
      value: 'N',
    },
    {
      label: 'O',
      value: 'O',
    },
    {
      label: 'P',
      value: 'P',
    },
    {
      label: 'Q',
      value: 'Q',
    },
    {
      label: 'R',
      value: 'R',
    },
    {
      label: 'S',
      value: 'S',
    },
    {
      label: 'T',
      value: 'T',
    },
    {
      label: 'U',
      value: 'U',
    },
    {
      label: 'V',
      value: 'V',
    },
    {
      label: 'W',
      value: 'W',
    },
    {
      label: 'X',
      value: 'X',
    },
    {
      label: 'Y',
      value: 'Y',
    },
    {
      label: 'Z',
      value: 'Z',
    },
  ],
};

export const heatMapDataa = {
  data: [
    {
      label: '20',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '1',
      U: '2',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '19',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '18',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '17',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '16',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '15',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '14',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '13',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '12',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '11',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '10',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '9',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '8',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '7',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '6',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '5',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '4',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '3',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '2',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
    {
      label: '1',
      Z: '0',
      Y: '0',
      X: '0',
      W: '0',
      V: '0',
      U: '0',
      T: '0',
      S: '0',
      R: '0',
      Q: '0',
      P: '0',
      O: '0',
      N: '0',
      M: '0',
      L: '0',
      K: '0',
      J: '0',
      I: '0',
      H: '0',
      G: '0',
      F: '0',
      E: '0',
      D: '0',
      C: '0',
      B: '0',
      A: '0',
    },
  ],
  summary: {
    value: 26170,
  },
  info: [],
  labels: [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
    {
      label: 'D',
      value: 'D',
    },
    {
      label: 'E',
      value: 'E',
    },
    {
      label: 'F',
      value: 'F',
    },
    {
      label: 'G',
      value: 'G',
    },
    {
      label: 'H',
      value: 'H',
    },
    {
      label: 'I',
      value: 'I',
    },
    {
      label: 'J',
      value: 'J',
    },
    {
      label: 'K',
      value: 'K',
    },
    {
      label: 'L',
      value: 'L',
    },
    {
      label: 'M',
      value: 'M',
    },
    {
      label: 'N',
      value: 'N',
    },
    {
      label: 'O',
      value: 'O',
    },
    {
      label: 'P',
      value: 'P',
    },
    {
      label: 'Q',
      value: 'Q',
    },
    {
      label: 'R',
      value: 'R',
    },
    {
      label: 'S',
      value: 'S',
    },
    {
      label: 'T',
      value: 'T',
    },
    {
      label: 'U',
      value: 'U',
    },
    {
      label: 'V',
      value: 'V',
    },
    {
      label: 'W',
      value: 'W',
    },
    {
      label: 'X',
      value: 'X',
    },
    {
      label: 'Y',
      value: 'Y',
    },
    {
      label: 'Z',
      value: 'Z',
    },
  ],
};

export const heatDataHuge = {
  data: [
    {
      label: '9-10',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '1 0',
      '>5': '3 0',
    },
    {
      label: '7-8',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '1 0',
      '>=5 <10': '2 0',
      '>5': '2 0',
    },
    {
      label: '5-6',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '0 0',
      '>5': '1 0',
    },
    {
      label: '3-4',

      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '3 0',
      '>5': '1 0',
    },
    {
      label: '1-2',
      '>=50': '0 0',
      '>=25 <50': '0 0',
      '>=10 <25': '0 0',
      '>=5 <10': '2 0',
      '>5': '0 0',
    },
  ],
  summary: {
    value: 26170,
  },
  info: [],
  labels: [
    {
      label: '>5',
      value: '>5',
    },
    {
      label: '>=5 <10',
      value: '>=5 <10',
    },
    {
      label: '>=10 <25',
      value: '>=10 <25',
    },
    {
      label: '>=25 <50',
      value: '>=25 <50',
    },
    {
      label: '>=50',
      value: '>=50',
    },
  ],
};

export const temp = {
  label: 'A',
  data: [
    { label: 'low', value: 49 },
    { label: 'medium', value: 51 },
  ],
};
