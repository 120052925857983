import React from 'react';

const TableChartViewIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7751_161634"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.974609" y="0.395996" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7751_161634)">
        <path
          d="M7.01628 18.396L5.97461 17.3543L11.4746 11.8543L14.4746 14.8543L18.9121 10.396L19.9746 11.4585L14.4746 16.9585L11.4746 13.9585L7.01628 18.396ZM4.47461 17.396C4.05794 17.396 3.70378 17.2491 3.41211 16.9554C3.12044 16.6616 2.97461 16.3085 2.97461 15.896V4.896C2.97461 4.49322 3.12391 4.14252 3.42253 3.84391C3.72114 3.5453 4.07183 3.396 4.47461 3.396H15.4746C15.8774 3.396 16.2281 3.5453 16.5267 3.84391C16.8253 4.14252 16.9746 4.49322 16.9746 4.896V8.22933H4.47461V17.396ZM4.47461 6.72933H15.4746V4.896H4.47461V6.72933Z"
          fill="#585858"
        />
      </g>
    </svg>
  );
};

export default TableChartViewIcon;
