import React from 'react';

const AttachMoneyIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7751_161639"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.974609" y="0.395996" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7751_161639)">
        <path
          d="M10.2249 17.396V15.7293C9.5166 15.5766 8.90896 15.2571 8.40202 14.771C7.89507 14.2849 7.53743 13.6807 7.3291 12.9585L8.87077 12.3335C8.98188 12.8335 9.26313 13.2814 9.71452 13.6772C10.1659 14.0731 10.6347 14.271 11.1208 14.271C11.6624 14.271 12.1069 14.1321 12.4541 13.8543C12.8013 13.5766 12.9749 13.2154 12.9749 12.771C12.9749 12.3543 12.8187 12.0071 12.5062 11.7293C12.1937 11.4516 11.6416 11.1738 10.8499 10.896C9.7666 10.5071 8.97493 10.08 8.47493 9.61475C7.97493 9.14947 7.72493 8.54877 7.72493 7.81266C7.72493 7.11822 7.95063 6.521 8.40202 6.021C8.85341 5.521 9.46105 5.19461 10.2249 5.04183V3.396H11.7249V5.04183C12.7249 5.2085 13.3951 5.59044 13.7354 6.18766C14.0756 6.78488 14.2527 7.09739 14.2666 7.12516L12.8083 7.72933C12.6972 7.36822 12.4749 7.06961 12.1416 6.8335C11.8083 6.59738 11.4402 6.47933 11.0374 6.47933C10.5513 6.47933 10.1485 6.60433 9.8291 6.85433C9.50966 7.10433 9.34994 7.40988 9.34994 7.771C9.34994 8.13211 9.52355 8.44113 9.87077 8.69808C10.218 8.95502 10.8638 9.24322 11.8083 9.56266C12.7805 9.88211 13.4888 10.2988 13.9333 10.8127C14.3777 11.3266 14.5999 11.9793 14.5999 12.771C14.5999 13.5627 14.343 14.2293 13.8291 14.771C13.3152 15.3127 12.6138 15.6529 11.7249 15.7918V17.396H10.2249Z"
          fill="#585858"
        />
      </g>
    </svg>
  );
};

export default AttachMoneyIcon;
