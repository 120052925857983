import axios from 'axios';
import qs from 'query-string';
import {
  API,
  baseURL,
  getAuthHeaders,
  eventNames,
  tokenKey,
  refreshKey,
} from '../constants';
import * as Sentry from '@sentry/react';
import { logAwsRumEvent, parseJwt } from '../utils';

let isRefreshing = false;
let failedQueue = [];
let retryOriginalRequest = null;

const settingsPaths = [
  'setting-user/login',
  'setting-user/verify-otp',
  'setting-user/resend-otp',
  'setting-user/forgot-password-otp',
  'setting-user/verify-forgot-password-otp',
  'setting-user/create-password',
  'setting-user/validate-create-password-otp',
  'setting-user/change-password',
  'setting-user/validate-change-password',
  'setting-user/get-refresh-access-token',
  'settings/login/',
  'settings/verify-otp/',
  'settings/resend-otp/',
  'settings/forgot-otp/',
  'settings/verify-forgot-otp/',
  'settings/update-password/',
  'settings/user/',
  'settings/create_password/',
  'settings/validate_createpassword_OTP/',
  'settings/change_password/',
  'settings/validate_change_password/',
];

export const client = axios.create({
  baseURL,
});

// Request interceptor to add start time
client.interceptors.request.use(
  (config) => {
    config.metadata = { startTime: new Date() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to calculate the duration
client.interceptors.response.use(
  (response) => {
    const endTime = new Date();
    response.config.metadata.endTime = endTime;
    response.duration = endTime - response.config.metadata.startTime;
    const metadata = {
      api: response.request.responseURL,
      duration: `${response.duration} ms`,
      status: response.status,
    };
    try {
      logAwsRumEvent(eventNames.api_response_duration, {
        metadata,
      });
    } catch (error) {
      console.log(error);
    }
    return response;
  },
  (error) => {
    const endTime = new Date();
    error.config.metadata.endTime = endTime;
    error.duration = endTime - error.config.metadata.startTime;
    const authToken = localStorage.getItem(tokenKey);
    const tokenData = parseJwt(authToken);
    const metadata = {
      api: error.request.responseURL,
      duration: `${error.duration} ms`,
      status: error.code,
      message: error.message,
      error: error?.response?.data?.error,
      userId: tokenData?.user_id,
    };
    try {
      logAwsRumEvent(
        `${tokenData?.user_id}_${eventNames.api_error_response_duration}`,
        metadata
      );
    } catch (error) {
      console.log(error);
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use(
  async (config) => {
    const isSettingsPath = settingsPaths.some((path) =>
      config.url.includes(path)
    );

    if (isSettingsPath) {
      return config;
    }

    const accessToken = localStorage.getItem(tokenKey);

    const tokenExp = getTokenExpiration(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);

    if (tokenExp - currentTime <= 300) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newTokens = await refreshToken();
          isRefreshing = false;
          failedQueue.forEach((cb) => cb(newTokens));
          failedQueue = [];
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${newTokens.access}`,
            },
          };
        } catch (error) {
          isRefreshing = false;
          clearAndReload();
          return Promise.reject(error);
        }
      } else {
        return new Promise((resolve) => {
          failedQueue.push((newTokens) => {
            resolve({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${newTokens.access}`,
              },
            });
          });
        });
      }
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!retryOriginalRequest) {
          retryOriginalRequest = originalRequest;
          try {
            const newTokens = await refreshToken();
            axios.defaults.headers.common.Authorization = `Bearer ${newTokens.access}`;
            retryOriginalRequest.headers.Authorization = `Bearer ${newTokens.access}`;
            return client(retryOriginalRequest);
          } catch (refreshError) {
            clearAndReload();
            return Promise.reject(refreshError);
          } finally {
            retryOriginalRequest = null;
          }
        }
      }
    } else {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

async function refreshToken() {
  const refreshTokenKey = localStorage.getItem(refreshKey);

  if (!refreshTokenKey) {
    clearAndReload();
  }

  const refreshTokenExp = getTokenExpiration(refreshTokenKey);
  const currentTime = Math.floor(Date.now() / 1000);

  if (currentTime >= refreshTokenExp) {
    clearAndReload();
  }

  try {
    const data = {
      refresh: refreshTokenKey,
    };
    const response = await axios.post(
      `${API}/setting-user/get-refresh-access-token`,
      data,
      {
        headers: {
          Authorization: `Bearer ${refreshTokenKey}`, // Include refresh token in the header
          'Content-Type': 'application/json',
        },
      }
    );

    const { access, refresh } = response.data;
    localStorage.setItem(tokenKey, access);
    localStorage.setItem(refreshKey, refresh);

    return { access, refresh };
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
}

function clearAndReload() {
  localStorage.clear();
  window.location.reload();
}

function getTokenExpiration(token) {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp;
}

export const success = (data) => {
  Sentry.captureMessage(data);
  return {
    status: 'success',
    isSuccessful: true,
    data,
  };
};

export const error = (error, data) => {
  Sentry.captureException(error, data);
  return {
    status: 'error',
    isSuccessful: false,
    message: error,
    data: data ?? null,
  };
};

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer token${1}`;
  const onSuccess = (response) => response;
  const onError = (error) => {
    // Optionally catch errors and add additional logging here
    return error;
  };
  return client(options).then(onSuccess).catch(onError);
};

export const axiosDel = async (url, headers, body) => {
  try {
    const res2 = await client.delete(url, {
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
      data: body ?? {},
    });
    if (res2.status === 204) {
      return { status: res2.status };
    } else if (res2.status === 200 || res2.status === 201) {
      return success(res2?.data);
    } else if (
      res2?.status === 203 ||
      res2?.status === 429 ||
      res2?.status === 500 ||
      res2?.status === 400 ||
      res2?.status === 404
    ) {
      Sentry.captureException(res2);
      return error(res2?.data?.error);
    }
  } catch (e) {
    if (e.response && e.response.data) {
      return error(e.response.data.error, e.response.data);
    }
    Sentry.captureException(e);
    console.log(e);
    return error(e);
  }
};

export const axiosUpdate = async (url, data, headers) => {
  try {
    const res2 = await client.put(url, data, {
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
    });
    if (res2.status === 200 || res2.status === 201) {
      return success(res2?.data);
    } else if (
      res2?.status === 203 ||
      res2?.status === 429 ||
      res2?.status === 500 ||
      res2?.status === 400 ||
      res2?.status === 404
    ) {
      return error(res2?.data?.error);
    }
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.data) {
      return error(e.response.data.error, e.response.data);
    }
    console.log(e);
    return error(e);
  }
};

export const axiosPost = async (url, data, headers) => {
  try {
    const res2 = await client.post(url, data, {
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
    });
    if (res2?.status === 200 || res2?.status === 201) {
      return success(res2?.data);
    } else if (
      res2?.status === 203 ||
      res2?.status === 429 ||
      res2?.status === 500 ||
      res2?.status === 400 ||
      res2?.status === 404
    ) {
      return error(res2?.data?.warning || res2?.data?.error);
    }
  } catch (e) {
    if (e?.response?.data) {
      return error(
        e?.response?.data?.warning || e?.response?.data?.error,
        e?.response?.data
      );
    }
    Sentry.captureException(e);
    console.log(e);
    return error(e);
  }
};

export const get = async (url, headers, data) => {
  try {
    const response = await client.get(url, {
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
    });
    if (response.data) {
      return success(response.data);
    } else if (
      response?.status === 203 ||
      response?.status === 429 ||
      response?.status === 500 ||
      response?.status === 400 ||
      response?.status === 404
    ) {
      return error(response?.data?.error);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.error);
    }
    Sentry.captureException(e);
    console.log(e);
    return error(e);
  }
};

export const download = async (url, data, headers) => {
  try {
    let response = null;
    if (data === null) {
      response = await client.get(url, {
        headers,
        responseType: 'blob',
      });
    } else {
      response = await client.post(url, data, {
        headers,
        responseType: 'blob',
      });
    }

    return success({
      file: response.data,
      filename: '',
    });
  } catch (e) {
    Sentry.captureException(e);
    return error(e);
  }
};

export const getWithBody = async (url, headers, body) => {
  try {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
      data: JSON.stringify(body),
    };

    const response = await client.request(config);
    if (response.data) {
      return success(response.data);
    } else if (
      response?.status === 203 ||
      response?.status === 429 ||
      response?.status === 500 ||
      response?.status === 400 ||
      response?.status === 404
    ) {
      return error(response?.data?.error);
    }
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.data) {
      return error(e.response.data.error);
    }
    console.log(e);
    return error(e);
  }
};

export const axiosGetAPI = async (url, queryObj) => {
  try {
    const query = qs.stringify(queryObj);
    const res2 = await client.get(`${API}${url}${query ? `?${query}` : ''}`, {
      headers: {
        ...getAuthHeaders(),
      },
    });
    if (res2.status === 200 || res2.status === 201) {
      return success(res2?.data);
    } else if (
      res2?.status === 203 ||
      res2?.status === 429 ||
      res2?.status === 500 ||
      res2?.status === 400 ||
      res2?.status === 404
    ) {
      return error(res2?.data?.error);
    }
  } catch (e) {
    if (e.response.data) {
      return error(e.response.data.error, e.response.data);
    }
    console.log(e);
    return error(e);
  }
};

// GET with Axios
export const axiosGet = async (url, queryObj) => {
  const query = qs.stringify(queryObj);
  return await client.get(`${url}?${query}`);
};

// Post with Axios
export const axiosPostRequest = async (url, queryObj, bodyObj) => {
  const query = qs.stringify(queryObj);
  return await client.post(`${url}${query ? `?${query}` : ''}`, bodyObj);
};
export const axiosPostRequestAPI = async (url, queryObj, bodyObj) => {
  const authHeaders = getAuthHeaders();
  const query = qs.stringify(queryObj);
  return await client.post(`${API}${url}?${query}`, bodyObj, {
    headers: authHeaders,
  });
};

// PUT with Axios
export const axiosPutRequest = async (url, queryObj, bodyObj) => {
  const query = qs.stringify(queryObj);
  return await client.put(`${url}?${query}`, bodyObj);
};
export const axiosPutRequestAPI = async (url, queryObj, bodyObj) => {
  const query = qs.stringify(queryObj);
  return await client.put(`${API}${url}?${query}`, bodyObj);
};

// Delete with Axios
export const axiosDelete = async (url, id) => {
  return await client.delete(`${url}/${id}`);
};

export const axiosDeleteAPI = async (url, queryObj, bodyObj) => {
  const authHeaders = getAuthHeaders();
  const query = qs.stringify(queryObj);
  return await axios.delete(`${API}${url}?${query}`, {
    headers: authHeaders,
    data: bodyObj,
  });
};

export const resourceExists = async (url) => {
  try {
    const response = await axios.head(url);
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};
