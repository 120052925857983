import React from 'react';

const ArrowDecrease = () => {
  return (
    <>
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: '5px' }}
      >
        <path
          d="M7 0.5L7 9.7L10.6 6.1L12 7.5L6 13.5L0 7.5L1.4 6.1L5 9.7L5 0.5H7Z"
          fill="#D83640"
        />
      </svg>
    </>
  );
};

export default ArrowDecrease;
