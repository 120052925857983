import * as d3 from 'd3';
import ColorParser from '../utils/colorParser';
import { colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import { hexToRgba } from '../utils/hexToRgba';

export const barRect = function barRect() {
  let config = {
    ...initialValues,
    barHeight: 16,
    barPadding: 2,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);
  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const yWidth = config.yScale.bandwidth() - config.barPadding;
      config.barHeight =
        config.authorDataLength && data?.length < 3 && yWidth <= 4
          ? yWidth * 4
          : config.barHeight < yWidth
          ? config.barHeight
          : yWidth;
      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'bar-group');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      function barHeight(eleRef) {
        eleRef
          .attr('height', (d) =>
            d.accValue === 0
              ? 0
              : config.graphType === 'group'
              ? config.barHeight / data.length
              : config.barHeight
          )
          .attr('y', (d, i) =>
            config.graphType === 'group'
              ? config.yScale(d.label) +
                (yWidth - config.barHeight) / 2 +
                d.labelIndex * (config.barHeight / data.length)
              : config.yScale(d.label) + (yWidth - config.barHeight) / 2
          );
      }
      function drawBar(eleRef) {
        eleRef
          .call(barHeight)
          .attr('x', (d) =>
            config.graphType === 'group'
              ? config.xScale(config.minX)
              : config.articleSentiment
              ? config.xScale(parseFloat(d.accValue - d.value)) +
                config.barPadding
              : config.xScale(parseFloat(d.accValue - d.value))
          )
          .attr('width', (d, i) => {
            const barHeight = config.articleSentiment
              ? config.xScale(parseFloat(d.value)) - config.barPadding
              : config.xScale(parseFloat(d.value));
            return barHeight;
          });
      }

      // Linear Gradient
      if (config?.colorGradientDark && config?.colorGradientLight) {
        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.colorGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.colorGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }
      if (config?.articleSentiment) {
        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-positive');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.greenGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.greenGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-negative');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.redGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.redGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );

        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');
              // defs.selectAll('*').remove();
              const gradientOffset = defs
                .append('linearGradient')
                .attr('class', 'gradientOffset')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('id', 'gradOffset-indicator-neutral');

              gradientOffset
                .append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config?.grayGradientLight)
                .attr('stop-opacity', config.colorOpacity || 1);

              gradientOffset
                .append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config?.grayGradientDark || '#fff')
                .attr('stop-opacity', config.colorOpacityBottom || 1);
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }

      // Define the gradients
      if (config.authorImpactChart) {
        selected
          .selectAll('.band-bg')
          .select('defs')
          .data((d, i) => {
            d.map((entry) => {
              const temp = entry;
              temp.labelIndex = i;
              return temp;
            });
            return d;
          })
          .join(
            (enter) => {
              const defs = enter.append('defs');

              if (config.noOfArticle) {
                const gradientOffsetArticles = defs
                  .append('linearGradient')
                  .attr('class', 'gradientOffset')
                  .attr('x1', '0%')
                  .attr('y1', '0%')
                  .attr('x2', '100%')
                  .attr('y2', '0%')
                  .attr('id', 'gradOffset-indicator-no-of-articles');

                gradientOffsetArticles
                  .append('stop')
                  .attr('offset', '0%')
                  .attr('stop-color', config?.colorGradientLightArticles)
                  .attr('stop-opacity', config.colorOpacity || 1);

                gradientOffsetArticles
                  .append('stop')
                  .attr('offset', '100%')
                  .attr(
                    'stop-color',
                    config?.colorGradientDarkArticles || '#fff'
                  )
                  .attr('stop-opacity', config.colorOpacityBottom || 1);
              }

              if (config.totalReach) {
                const gradientOffsetReach = defs
                  .append('linearGradient')
                  .attr('class', 'gradientOffset')
                  .attr('x1', '0%')
                  .attr('y1', '0%')
                  .attr('x2', '100%')
                  .attr('y2', '0%')
                  .attr('id', 'gradOffset-indicator-total-reach');

                gradientOffsetReach
                  .append('stop')
                  .attr('offset', '0%')
                  .attr('stop-color', config?.colorGradientLightReach)
                  .attr('stop-opacity', config.colorOpacity || 1);

                gradientOffsetReach
                  .append('stop')
                  .attr('offset', '100%')
                  .attr('stop-color', config?.colorGradientDarkReach || '#fff')
                  .attr('stop-opacity', config.colorOpacityBottom || 1);
              }

              if (config.totalAVE) {
                const gradientOffsetAVE = defs
                  .append('linearGradient')
                  .attr('class', 'gradientOffset')
                  .attr('x1', '0%')
                  .attr('y1', '0%')
                  .attr('x2', '100%')
                  .attr('y2', '0%')
                  .attr('id', 'gradOffset-indicator-total-ave');

                gradientOffsetAVE
                  .append('stop')
                  .attr('offset', '0%')
                  .attr('stop-color', config?.colorGradientLightAVE)
                  .attr('stop-opacity', config.colorOpacity || 1);

                gradientOffsetAVE
                  .append('stop')
                  .attr('offset', '100%')
                  .attr('stop-color', config?.colorGradientDarkAVE || '#fff')
                  .attr('stop-opacity', config.colorOpacityBottom || 1);
              }
            },
            (update) => update,
            (exit) => {
              exit.remove();
            }
          );
      }

      const handleColorGradient = (d) => {
        if (config.colorGradientDark) {
          return 'url(#gradOffset-indicator)';
        } else if (config.noOfArticle) {
          return 'url(#gradOffset-indicator-no-of-articles)';
        } else if (config.totalReach && !config.noOfArticle) {
          return 'url(#gradOffset-indicator-total-reach)';
        } else if (config.totalAVE) {
          return 'url(#gradOffset-indicator-total-ave)';
        } else if (config.articleSentiment) {
          if (d.labelText === 'Positive') {
            return 'url(#gradOffset-indicator-positive)';
          } else if (d.labelText === 'Negative') {
            return 'url(#gradOffset-indicator-negative)';
          } else {
            return 'url(#gradOffset-indicator-neutral)';
          }
        }
      };

      const handleDropShadow = (d, i) => {
        if (config.colorGradientDark || config.authorImpactChart) {
          const color = config.dropShadowColor || ColorParser(colorBox[i]);
          const rgbaColor = hexToRgba(color, 0.3);
          return `drop-shadow(-5px 0px 6px ${rgbaColor})`;
        } else if (config.articleSentiment) {
          if (d.labelText === 'Positive') {
            const color =
              config.greenDropShadowColor || ColorParser(colorBox[i]);
            const rgbaColor = hexToRgba(color, 0.3);
            return `drop-shadow(-5px 0px 6px ${rgbaColor})`;
          } else if (d.labelText === 'Negative') {
            const color = config.redDropShadowColor || ColorParser(colorBox[i]);
            const rgbaColor = hexToRgba(color, 0.3);
            return `drop-shadow(-5px 0px 6px ${rgbaColor})`;
          } else {
            const color =
              config.grayDropShadowColor || ColorParser(colorBox[i]);
            const rgbaColor = hexToRgba(color, 0.3);
            return `drop-shadow(-5px 0px 6px ${rgbaColor})`;
          }
        }
      };

      selected
        .selectAll('.bar-group')
        .selectAll('rect')
        .data((d, i) => {
          d.map((entry) => {
            const temp = entry;
            temp.labelIndex = i;
            return temp;
          });
          return d;
        })
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'column-rect')
              .attr('data-gi', (d) => d.labelIndex)
              .style('fill', (d, i) =>
                handleColorGradient(d)
                  ? handleColorGradient(d)
                  : d.color
                  ? ColorParser(d.color)
                  : '#000'
              )
              // .attr('rx', config?.colorGradientDark && 4)
              // .style('filter', (d, i) => {
              //   // config?.colorGradient &&
              //   // (d.value < 0
              //   //   ? 'url(#drop-shadow-negative)'
              //   //   : 'url(#drop-shadow-positive)')
              //   const color =
              //     config.dropShadowColor || ColorParser(colorBox[i]);
              //   const rgbaColor = hexToRgba(color, 0.3);
              //   return (
              //     config?.colorGradientDark &&
              //     `drop-shadow(-5px 0px 6px ${rgbaColor})`
              //   );
              // })
              .attr(
                'rx',
                (config?.colorGradientDark ||
                  config.articleSentiment ||
                  config.authorImpactChart) &&
                  4
              )
              .style('filter', (d, i) => handleDropShadow(d, i))
              // .style('filter',  'url(#drop-shadow)')
              .attr('x', 0)
              .attr('width', 0)
              .call(barHeight)
              .transition(t)
              .call(drawBar);
          },
          (update) => update.transition(t).call(drawBar),
          (exit) => {
            exit.transition(t).attr('height', 0).attr('width', 0).remove();
          }
        );
      setUpEvents(config, selected, 'column-rect');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
