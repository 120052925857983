import React from 'react';

const ArrowIncrease = () => {
  return (
    <>
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: '5px' }}
      >
        <path
          d="M5 13.5V4.3L1.4 7.9L0 6.5L6 0.5L12 6.5L10.6 7.9L7 4.3V13.5H5Z"
          fill="#24B629"
        />
      </svg>
    </>
  );
};

export default ArrowIncrease;
