import React from 'react';

const DynamicFeedIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7751_161649"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.974609" y="0.395996" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7751_161649)">
        <path
          d="M4.47461 17.396C4.05794 17.396 3.70378 17.2491 3.41211 16.9554C3.12044 16.6616 2.97461 16.3085 2.97461 15.896V10.396H4.47461V15.896H11.9746V17.396H4.47461ZM7.47461 14.396C7.05794 14.396 6.70378 14.2491 6.41211 13.9554C6.12044 13.6616 5.97461 13.3085 5.97461 12.896V7.396H7.47461V12.896H14.9746V14.396H7.47461ZM10.4746 11.396C10.0621 11.396 9.70898 11.2491 9.41523 10.9554C9.12148 10.6616 8.97461 10.3085 8.97461 9.896V4.896C8.97461 4.4835 9.12148 4.13037 9.41523 3.83662C9.70898 3.54287 10.0621 3.396 10.4746 3.396H17.4746C17.8871 3.396 18.2402 3.54287 18.534 3.83662C18.8277 4.13037 18.9746 4.4835 18.9746 4.896V9.896C18.9746 10.3085 18.8277 10.6616 18.534 10.9554C18.2402 11.2491 17.8871 11.396 17.4746 11.396H10.4746ZM10.4746 9.896H17.4746V6.396H10.4746V9.896Z"
          fill="#585858"
        />
      </g>
    </svg>
  );
};

export default DynamicFeedIcon;
